import React from 'react';
import {
    ContestYear
} from "../../Client";

interface IContestLogoProps {
    year: ContestYear | undefined | null;
    size: number;
    cssClass: string;
}

function ContestLogo(props: IContestLogoProps): JSX.Element {
    if (props.year === undefined || props.year === null) {
        return (<React.Fragment/>);
    }

    const thisYear = props.year.toString();
        
    const src = `${process.env.REACT_APP_API_URL}api/Contest/${thisYear}/Logo/1`;

    const srcSet = `${process.env.REACT_APP_API_URL}api/Contest/${thisYear}/Logo/1 1x, ${process.env.REACT_APP_API_URL}api/Contest/${thisYear}/Logo/1.5 1.5x, ${process.env.REACT_APP_API_URL}api/Contest/${thisYear}/Logo/2 2x, ${process.env.REACT_APP_API_URL}api/Contest/${thisYear}/Logo/3 3x, ${process.env.REACT_APP_API_URL}api/Contest/${thisYear}/Logo/4 4x`;

    return (<img src={src} srcSet={srcSet} alt={thisYear} className={props.cssClass} height={props.size} width={props.size}/>);

}

export default ContestLogo;