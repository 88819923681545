import React from 'react';
import {
    User
} from "../../Client";

interface IUserImageProps {
    user: User | undefined | null;
    size: number;
}

function UserImage(props: IUserImageProps): JSX.Element {
    if (props.user === null || props.user=== undefined) {
        return (<React.Fragment/>);
    }

    function src() {
        return `${process.env.REACT_APP_API_URL}api/User/${props.user?.Id}/Image/1`;
    }

    function srcSet() {
        const id = props.user?.Id;

        return `${process.env.REACT_APP_API_URL}api/User/${id}/Image/1 1x, ${process.env.REACT_APP_API_URL}api/User/${id}/Image/1.5 1.5x, ${process.env.REACT_APP_API_URL}api/User/${id}/Image/2 2x, ${process.env.REACT_APP_API_URL}api/User/${id}/Image/3 3x, ${process.env.REACT_APP_API_URL}api/User/${id}/Image/4 4x,`;

    }

    return(<img className="rounded"
                alt={props.user.Name?.toString()}
                src={src()}
                srcSet={srcSet()}
                width={props.size} height={props.size}/>);

}

export default UserImage;