import React from 'react';
import {
    ArtistRelationship,
    Song,
    RelationshipTypes,
    User
} from "../../Client";

import ContestLogo from "../Contest/ContestLogo";
import UserImage from "../User/UserImage";

interface IAboutViewProps {
    song: Song | undefined;
    drawnBy? : User;
}

interface IAlsoAppearedItemProps {
    artistRelationship : ArtistRelationship;
}

interface IDrawnByProps {
    drawnBy? : User;
}

function AlsoAppearedItem(props: IAlsoAppearedItemProps) {
    if (props.artistRelationship.Year === null || props.artistRelationship.Name === null || props.artistRelationship.RelationshipType === null) {
        return(<React.Fragment/>);
    }

    if (props.artistRelationship.Year === undefined || props.artistRelationship.Name === undefined || props.artistRelationship.RelationshipType === undefined) {
        return(<React.Fragment/>);
    }

    function getText() : string {
        if (props.artistRelationship.Year === null || props.artistRelationship.Name === null || props.artistRelationship.RelationshipType === null) {
            return "&nbsp;";
        }

        if (props.artistRelationship.Year === undefined || props.artistRelationship.Name === undefined || props.artistRelationship.RelationshipType === undefined) {
            return "&nbsp;"
        }
        
        if (props.artistRelationship.RelationshipType.Value === RelationshipTypes._0) {
            return props.artistRelationship.Year.toString();
        }
        
        if (props.artistRelationship.RelationshipType.Value === RelationshipTypes._1) {
            return `as ${props.artistRelationship.Name.toString()}`;
        }
        
        if (props.artistRelationship.RelationshipType.Value === RelationshipTypes._2) {
            return `in ${props.artistRelationship.Name.toString()}`;
        }
        return "&nbsp;";
    }

    return (<li className="m-1 float-start">
                <div className="row">
                    <ContestLogo year={props.artistRelationship.Year} size={120} cssClass="rounded d-none d-md-block d-lg-block d-xl-block"/>
                    <ContestLogo year={props.artistRelationship.Year} size={75} cssClass="rounded d-block d-md-none d-lg-none d-xl-none"/>
                </div>
                <div className="row">
                    <span className="text-small w-100 text-center">
                        {getText()}
                    </span>
                </div>
            </li>);
}

function AlsoAppeared(props: IAboutViewProps) : JSX.Element {
    if (props.song === undefined || props.song === null || props.song.AlsoAppeared === undefined || props.song.AlsoAppeared === null || props.song.AlsoAppeared.length === 0) {
        return(<React.Fragment/>);
    }


    function items() : Array<JSX.Element> {
        if (props.song === undefined || props.song === null || props.song.AlsoAppeared === undefined || props.song.AlsoAppeared === null || props.song.AlsoAppeared.length === 0) {
            return new Array<JSX.Element>();
        }

        return props.song.AlsoAppeared.map(alsoAppeared => {
            var key = `ALSOAPPEARED|${alsoAppeared.Year?.toString()}`;

            return (<AlsoAppearedItem artistRelationship={alsoAppeared} key={key}/>);
        });
    }

    return (<div className="p-2">
                <div className="row">
                    <div className="col-12">
                        <span className="text-small fw-bold">Also Competed In</span>
                        <ul className="list-unstyled">
                            {items()}
                        </ul>
                    </div>
                </div>
            </div>);

}

function DrawnBy(props: IDrawnByProps): JSX.Element {
    if (props.drawnBy === null || props.drawnBy === undefined) {
        return (<React.Fragment/>);
    }

    return(<div className="p-1">
                <div className="row">
                    <div className="col-12 text-center">
                        <span className="text-small fw-bold">Drawn by...</span>
                    </div>
                </div>
               <div className="row p-2">
                    <div className="col-12 text-center">
                        <UserImage user={props.drawnBy} size={250}/>
                    </div>
               </div>
               <div className="row p-2">
                    <div className="col-12 text-center">
                        <span className="text-small">{props.drawnBy.Name}</span>
                    </div>
               </div>
           </div>);
}

function AboutTab(props: IAboutViewProps) {
    if (props.song === undefined || props.song.Blurb === undefined) {
        return(<React.Fragment/>);
    }

    return( <div>
                <div className="p-2">
                    <span dangerouslySetInnerHTML={{__html: props.song.Blurb.toString() }}></span>
                </div>
                <AlsoAppeared song={props.song}/>
                <DrawnBy drawnBy={props.drawnBy}/>
            </div>);
}

export default AboutTab;