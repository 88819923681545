import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router';
import AxiosSetup from "../../api/AxiosSetup";
import FlagLarge from "../Country/FlagLarge";

import {
    AdminClient,
    ApplicationUser,
    Country,
    ContestYear
} from "../../Client";

let axiosSetup = new AxiosSetup();

let client = new AdminClient(undefined, axiosSetup.Create());

interface ICountryToDrawProps {
    user : ApplicationUser | undefined;
    country: Country;
    year: ContestYear | undefined;
}

function CountryToDraw(props: ICountryToDrawProps) {
    const navigate = useNavigate();

    function handleClick() {
        if (props.user === undefined) {
            return;
        }

        const promise = client.addToDraw({
            UserId: props.user.UserId?.toString(),
            CountryId: props.country.Id as number
        });

        promise.then(() => {
            navigate("/Admin/Users");
        }).catch(async ex  => {
            if (ex.status === 401 || ex.status === 403) {
                await axiosSetup.Refresh();
            }

            navigate("/");
        });
    }
    
    return (
        <li className="clickable float-start m-2" onClick={() => handleClick()}>
            <FlagLarge country={props.country} year={props.year} css={undefined}/>
        </li>);
}

interface IAdminDrawScreenProps {
    year: ContestYear | undefined;
}

function AdminDrawScreen(props: IAdminDrawScreenProps): JSX.Element {
    const {
        userId
    } = useParams();

    const navigate = useNavigate();

    const [
        user, setUser
    ] = useState<ApplicationUser |
                 undefined>(undefined);

    const[
        countries, setCountries
    ] = useState<Country[] | null | undefined>(new Array<Country>());

    useEffect(() => {
        var promise = client.undrawn(userId);

        promise.then(model => {
            if (model !== undefined) {
                setUser(model.User);

                if (model.Countries === null || model.Countries === undefined || model.Countries.length === 0) {
                    navigate("/Admin/User");
                }

                setCountries(model.Countries);
            }

        }).catch(async ex  => {
            if (ex.status === 401 || ex.status === 403) {
                await axiosSetup.Refresh();
            }

            navigate("/");
        });
    }, [props]);

    function items(): Array<JSX.Element> {
        if (countries === null || countries === undefined) {
            return new Array<JSX.Element>();
        }

        return countries.map(item => {
            var key = `DRAW|${item.Id}`;

            return (<CountryToDraw key={key} user={user} country={item} year={props.year}/>);
        });
    }

    if (user === undefined) {
        return (<React.Fragment/>);
    }

    return (
        <div className="m-1">
            <div className="row">
                <div className="col-12 text-white">
                    <h2>{user.UserName}</h2>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <ul className="list-unstyled">
                        {items()}
                    </ul>
                </div>
            </div>
        </div>);
}

export default AdminDrawScreen;