import React, { useEffect } from 'react';
import AxiosSetup from "../api/AxiosSetup";

function LogoutView() : JSX.Element {
    
    const axiosSetup = new AxiosSetup();

    useEffect(() => {
            axiosSetup.Logout();

            window.location.href = "/";
        },
        []);

    return (<React.Fragment/>);
}

export default LogoutView;