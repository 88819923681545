import React from 'react';


import {
    Frame,
    ContestYear,
    FrameType,
    User
} from "../../Client";

import CurrentScore from "./CurrentScore";
import UserImage from "../User/UserImage";

interface ICurrentVotesProps {
    user: User | undefined;
    frame: Frame;
    year: ContestYear | undefined;
}

function CurrentVotes(props: ICurrentVotesProps): JSX.Element {
    function items(): Array<JSX.Element> {
        const frame = props.frame;

        if (frame === undefined) {
            return new Array<JSX.Element>();
        }

        if (frame.CurrentVotes === undefined || frame.CurrentVotes === null || frame.CurrentVotes.length === 0) {
            return new Array<JSX.Element>();
        }

        var latestVote = frame.CurrentVotes[frame.CurrentVotes.length - 1].Country?.Id;

        return frame.CurrentVotes.map(item => {
            var key = `CV|${item.Country?.Id}`;

            return (<CurrentScore key={key} year={props.year} frame={item} latest={latestVote === item.Country?.Id}/>);
        });
    }

    if (props.user == undefined || props.frame.FrameType == FrameType._3 || props.frame.FrameType == FrameType._4) {
        return (<div className="row current-voting-block">
                </div>);
    }

    return (<div className="row m-2 d-flex flex-nowrap animate__animated animate__flipInX current-voting-block">
                <div className="col-2 d-block mx-auto">
                    <div className="row">
                        <div className="col-12 d-block mx-auto">
                            <UserImage user={props.user} size={100}/>
                        </div>
                        <div className="col-12 d-block mx-auto">
                            <span className="text-light text-large text-center">
                                {
                                    props.user.Name
                                }
                            </span>
                        </div>
                    </div>
                </div>
                <div className="col-10">
                    <ul className="d-flex justify-content-start w-100 list-unstyled list-inline">
                        {items()}
                    </ul>
                </div>
            </div>);

}

export default CurrentVotes;