import axios, { AxiosInstance } from 'axios';
import { IdentityClient } from "../Client";


class AxiosSetup {
    public Create(): AxiosInstance {
        return axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            transformResponse: data => data
        });
    }

    public Refresh() : Promise<boolean> {
        const identityClient = new IdentityClient(undefined,
            this.Create());

        const stored = localStorage.getItem("auth");

        if (stored != null) {
            const token = JSON.parse(stored);

            const promise = identityClient.refresh(token);

            var retVal = false;
            promise.then(newToken => {
                    localStorage.setItem("auth",
                        JSON.stringify(newToken));

                    retVal = true;
                })
                .catch(ex => {
                    console.log(ex);

                    localStorage.removeItem("auth");
                });

            return Promise.resolve(retVal);
        }

        return Promise.resolve(false);
    }

    public Authenticated(): boolean {
        const stored = localStorage.getItem("auth");

        if (stored != null) {
            return true;
        }

        return false;
    }

    public Logout(): void {
        localStorage.removeItem("auth");
    }
}

export default AxiosSetup;