import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart, faHeartbeat } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faOpenHeart, faThumbsUp } from "@fortawesome/free-regular-svg-icons";

import {
    FrameScoreboard,
} from "../../Client";

interface IScoreCountProps {
    frame: FrameScoreboard
}

function ScoreCount(props: IScoreCountProps): JSX.Element {
    function twelvePoints() : Array<JSX.Element> {
        const retVal = new Array<JSX.Element>();

        for (let i = 0; i < (props.frame.Count12 as number); i++) {
            const key = Math.random()
                .toString() + ".12P." + i.toString();

            retVal.push(<FontAwesomeIcon key={key} icon={faHeartbeat}/>);
        }

        return retVal;
    }

    function tenPoints(): Array<JSX.Element> {
        const retVal = new Array<JSX.Element>();

        for (let i = 0; i < (props.frame.Count10 as number); i++) {
            const key = Math.random()
                .toString() + ".10P." + i.toString();

            retVal.push(<FontAwesomeIcon key={key} icon={faHeart}/>);
        }

        return retVal;
    }

    function eightPoints(): Array<JSX.Element> {
        const retVal = new Array<JSX.Element>();

        for (let i = 0; i < (props.frame.Count8 as number); i++) {
            const key = Math.random()
                .toString() + ".8P." + i.toString();

            retVal.push(<FontAwesomeIcon key={key} icon={faOpenHeart}/>);
        }

        return retVal;

    }

    function otherPoints(): Array<JSX.Element> {
        const totalOther = (props.frame.Count7 as number) + (props.frame.Count6 as number) + (props.frame.Count5 as number) + (props.frame.Count4 as number) + (props.frame.Count3 as number) + (props.frame.Count2 as number) + (props.frame.Count1 as number);

        const retVal = new Array<JSX.Element>();

        for (let i = 0; i < totalOther; i++) {
            const key = Math.random()
                .toString() + ".OP." + i.toString();

            retVal.push(<div className="pe-1 d-inline-block">
                            <FontAwesomeIcon key={key} icon={faThumbsUp}/>
                        </div>);
        }

        return retVal;
    }

    return(<div>
        {otherPoints()}
        {eightPoints()}
        {tenPoints()}
        {twelvePoints()}
        </div>);
}

export default ScoreCount;
