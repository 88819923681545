import React from 'react';
import {
    Lyric
} from "../../Client";

interface ILyricsTabProps {
    lyric: Lyric
};

function LyricsTab(props: ILyricsTabProps) {
    if (props.lyric === undefined || props.lyric.Lyrics === undefined) {
        return (<React.Fragment/>);
    }

    return(<div className="p-2 pb-0">
               <span dangerouslySetInnerHTML={{__html: props.lyric.Lyrics.toString() }}/>
           </div>);

}

export default LyricsTab;