import {
    Country
} from "../../Client";

function GetIsoCode(country: Country | undefined): string {
    if (country === null || country === undefined || country.Code === null || country.Code === undefined) {
        return "UNK";
    }

    const work = country.Code["Iso31663"];

    if (work === null || work === undefined) {
        return "UNK";
    }

    return work.toString();
}

export default GetIsoCode;