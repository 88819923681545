import React, { useEffect, useState } from 'react';
import AxiosSetup from "../../api/AxiosSetup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faUpload } from "@fortawesome/free-solid-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import ImageUploading from 'react-images-uploading';

import {
    UserClient,
    UserSettingsModel,
    IsHashTagEurovisionEnabled,
    IsHashTagEscEnabled,
    IsHashTagCountryEnabled,
    IsHashTagTagLineEnabled,
    UserHashTags,
    IsTweetDouzePointsEnabled,
    FileParameter,
    User,
    UserId
} from "../../Client";
import { Modal, Spinner } from 'react-bootstrap';
import UserImage from '../User/UserImage';

let axiosSetup = new AxiosSetup();

var client = new UserClient(undefined,
    axiosSetup.Create());

interface ISaveButtonProps {
    saving: boolean;
    handleSubmit() : void;
}

function SaveButton(props: ISaveButtonProps) : JSX.Element {
    if (props.saving) {
        return (
            <div className="btn btn-primary btn-lg disabled">
                <Spinner animation="border" size="sm" as="span" role="status"/>&nbsp;Saving...
            </div>
        );
    }

    return(
        <div className="btn btn-primary btn-lg" onClick={(event) => props.handleSubmit()}>
            <FontAwesomeIcon icon={faSave}/>&nbsp;Save
        </div>
    );
}

interface ITwitterSettingProps {
    settings: UserSettingsModel;
}

function TwitterSetting(props : ITwitterSettingProps): JSX.Element {
    const[
        isEurovisionChecked, setIsEurovisionChecked
    ] = useState<boolean>(props.settings.HashTagEurovision as boolean ?? false);

    const [
        isHashTagCountryChecked, setIsHashTagCountryChecked
    ] = useState<boolean>(props.settings.HashTagCountry as boolean ?? false);

    const [
        isTaglineChecked, setIsTaglineChecked
    ] = useState<boolean>(props.settings.HashTagTagline as boolean ?? false);

    const [
        isTweetDouzePointsChecked, setIsTweetDouzePointsChecked
    ] = useState<boolean>(props.settings.PublishDouzePoints as boolean ?? false);

    const [
        userHashTags, setUserHashTags
    ] = useState<string>(props.settings?.UserHashTags?.toString() ?? "");

    const [
        saving, setSaving
    ] = useState<boolean>(false);

    const[
        show, setShow
    ] = useState(false);

    function handleSubmit(): void {
        if (props.settings.ScreenName === undefined) {
            return;
        }

        setSaving(true);

        const userSettingModel = {
            ScreenName : props.settings.ScreenName,
            HashTagEurovision: isEurovisionChecked as IsHashTagEurovisionEnabled,
            HashTagEsc: false as IsHashTagEscEnabled,
            HashTagCountry: isHashTagCountryChecked as IsHashTagCountryEnabled,
            HashTagTagLine: isTaglineChecked as IsHashTagTagLineEnabled,
            UserHashTags: userHashTags as UserHashTags,
            PublishDouzePoints: isTweetDouzePointsChecked as IsTweetDouzePointsEnabled
        };

        const promise = client.settingsPOST(userSettingModel);

        promise.then(() => {
            setSaving(false);
        }).catch(async ex  => {
            if (ex.status === 401 || ex.status === 403) {
                await axiosSetup.Refresh();
            }

            handleSubmit();
        });
    }

    function handleDisconnect() {
        const promise = client.disconnect();

        promise.then(() => {
            setShow(false);
            window.location.reload();
        }).catch(async ex  => {
            if (ex.status === 401 || ex.status === 403) {
                await axiosSetup.Refresh();
            }

            handleDisconnect();
        });
    }

    function handleClose() {
        setShow(false);
    }

    function handleShow() {
        setShow(true);
    }

    return (<div className="row me-1 mt-1">
                <div className="col-12 col-md-10 offset-md-1">
                    <div className="card border-light text-dark">
                        <div className="card-header bg-light">
                            Tweeting via @{props.settings.ScreenName}
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <span className="settings-label">Automatically add</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 col-md-2 text-end">
                                    <label className="switch">
                                        <input type="checkbox" id="HashTagEurovision" name="HashTagEurovision" data-toggle="toggle" value="true" checked={isEurovisionChecked} onChange={(event) => { setIsEurovisionChecked(event.target.checked);}}/>
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="col-8 col-md-10">
                                    <span className="settings-label fw-bold">#Eurovision</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 col-md-2 text-end">
                                    <label className="switch">
                                        <input type="checkbox" id="HashTagCountry" name="HashTagCountry" data-toggle="toggle" value="true" checked={isHashTagCountryChecked} onChange={(event) => { setIsHashTagCountryChecked(event.target.checked);}}/>
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="col-8 col-md-10">
                                    <span className="settings-label">
                                        Country Name & Flag
                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 col-md-2 text-end">
                                    <label className="switch">
                                        <input type="checkbox" id="HashTagTagline" name="HashTagTagline" data-toggle="toggle" value="true" checked={isTaglineChecked} onChange={(event) => { setIsTaglineChecked(event.target.checked);}}/>
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="col-8 col-md-10">
                                    <span className="settings-label fw-bold">
                                        #UnitedByMusic
                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    &nbsp;
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 col-md-2 text-end">
                                    <label className="switch">
                                        <input type="checkbox" id="PublishDouzePoints" name="PublishDouzePoints" data-toggle="toggle" value="true" checked={isTweetDouzePointsChecked} onChange={(event) => {setIsTweetDouzePointsChecked(event.target.checked);}}/>
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                                <div className="col-8 col-md-10">
                                    <span className="settings-label">
                                        Tweet your Points at the end of the contest
                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    &nbsp;
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4 col-md-2">
                                    <span className="settings-label">Custom HashTags</span>
                                </div>
                                <div className="col-8 col-md-10">
                                    <input type="text" id="UserHashTags" name="UserHashTags" value={userHashTags} className="form-control settings-text" onChange={(event) => { setUserHashTags(event.target.value);}}/>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="row">
                                <div className="col-12 text-end">
                                    <button className="btn btn-lg btn-danger" type="button" onClick={() => handleShow()}><FontAwesomeIcon icon={faTwitter}/>&nbsp;Disconnect</button>
                                    &nbsp;
                                    <SaveButton saving={saving} handleSubmit={handleSubmit}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header>
                        <Modal.Title className="settings-modal">Disconnect from Twitter?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="settings-modal">
                        <p>Are you sure you want to stop tweeting via @{props.settings.ScreenName}?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type="button" className="btn btn-info" onClick={handleClose}>Cancel</button>
                        &nbsp;
                        <button className="btn btn-danger" onClick={() => handleDisconnect()}><FontAwesomeIcon icon={faTwitter}/>&nbsp;Disconnect from Twitter</button>
                    </Modal.Footer>
                </Modal>
            </div>);

}

interface IIMageUploaderProps {
    userId: UserId | undefined;
}

function ImageUploader(props : IIMageUploaderProps): JSX.Element {
    const [images, setImages] = React.useState([]);

    if (props.userId === undefined) {
        return (<React.Fragment/>);
    }

    const onChange = (imageList:any, addUpdateIndex:any) => {
        var image = imageList[0];

        var blob = dataURLtoBlob(image.data_url);
        var fileName = image.file.name;

        const file = {
            data: blob,
            fileName: fileName
        } as FileParameter;

        const promise = client.upload(file);

        promise.then(x => {
            window.location.reload();
        }).catch(async ex => {
            if (ex.status === 401 || ex.status === 403) {
                await axiosSetup.Refresh();
            }
        });
    };

    function dataURLtoBlob(dataurl:string) :Blob|null {
        var arr = dataurl.split(",");

        if (arr[0] === null) {
            return null;
        }

        var mimeArr = arr[0].match(/:(.*?);/);
    
        if (mimeArr === null || mimeArr[1] === null) {
            return null;
        }

        var mime = mimeArr[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
    
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    }

    function toUser(): User {
        return {
            Id : props.userId,
            Name : "God Damned Hippy!"
        } as User;
    }

    return( <div className="row me-1 mt-1">
                <div className="col-12 col-md-10 offset-md-1">
                    <div className="card border-light text-dark">
                        <div className="card-header">
                            Profile Picture
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12 text-center">
                                    <UserImage user={toUser()} size={250}/>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer">
                            <div className="row">
                                <div className="col-12 text-end">
                                    <ImageUploading
                                        value={images}
                                        onChange={onChange}
                                        maxNumber={1}
                                        dataURLKey="data_url">
                                        {({
                                            imageList,
                                            onImageUpload,
                                            onImageRemoveAll,
                                            onImageUpdate,
                                            onImageRemove,
                                            isDragging,
                                            dragProps,
                                        }:any) => (
                                            <div className="btn btn-primary btn-lg" onClick={onImageUpload}>
                                            <FontAwesomeIcon icon={faUpload}/>&nbsp;Upload New Profile Picture
                                            </div>
                                        )}
                                        </ImageUploading>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>);

}


function SettingsForm() : JSX.Element {
    const [
        userSettings, setUserSettings
    ] = useState<UserSettingsModel |
                 undefined>(undefined);

    const[
        loaded, setLoaded
    ] = useState(false);

    const[userId, setUserId] = useState<UserId | undefined>(undefined);

    useEffect(() => {
            const promise = client.settingsGET();

            promise.then(settings => {
                    if (settings !== undefined && settings !== null) {
                        setUserSettings(settings);
                    }

                    const secondPromise = client.identity();

                    secondPromise.then(identity => { 
                        if (identity !== undefined && identity !== null) {
                            setUserId(identity);
                        }

                        setLoaded(true);
                    })
                    .catch(async ex => {
                        if (ex.status === 401 || ex.status === 403) {
                            await axiosSetup.Refresh();
                        }
                    });
                })
                .catch(async ex => {
                    if (ex.status === 401 || ex.status === 403) {
                        await axiosSetup.Refresh();
                    }
                });
        },
        []);

    if (!loaded) {
        return(<React.Fragment/>);
    }

    function connectToTwitter() {
        const url = process.env.REACT_APP_API_URL + "api/OAuth/Begin";

        window.location.href = url;
    }

    if (userSettings === undefined || userSettings === null) {
        return (<div>
                    <ImageUploader userId={userId} />
                    <div className="row me-0 h-100">
                        <div className="col-4 offset-4 login-panel">
                            <div className="card border-light">
                                <div className="card-header">
                                    Connect
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">&nbsp;</div>
                                        <div className="col-12 text-center">
                                            <a className="btn btn-primary btn-lg w-100" onClick={() => connectToTwitter()}><FontAwesomeIcon icon={faTwitter}/> &nbsp;Connect to Twitter</a>
                                        </div>
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>);
    }

    return(
        <div>
            <ImageUploader userId={userId} />
            <TwitterSetting settings={userSettings}/>
        </div>);
}

export default SettingsForm;