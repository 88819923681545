import React, { useEffect, useState } from 'react';
import NavigationBar from "./Components/NavigationBar";
import AxiosSetup from "./api/AxiosSetup";
import { Container } from 'react-bootstrap';
import {
    createSignalRContext, // SignalR
} from "react-signalr";


import {
    ContestClient,
    ContestYear,
    ResultsAvailable,
    ContestNumberOfSongs,
    RunningOrder,
    ContestCloseTime
} from "./Client";

import {
    BrowserRouter,
    Route,
    Routes,
} from 'react-router-dom';

import LoginView from "./Components/LoginView";
import LogoutView from "./Components/LogoutView";
import OverviewView from "./Components/Overview";
import ScoresheetView from "./Components/Scoresheet"
import SettingsForm from "./Components/Settings/SettingsForm";
import HistoryScreen from "./Components/HistoryScreen";
import HistoryContestScreen from "./Components/HistoryContestScreen";
import ResultPresenterScreen from "./Components/ResultPresenterScreen";
import HistoryDetailScreen from "./Components/HistoryDetailScreen";
import ResultScreen from "./Components/ResultScreen";
import ResultDetailScreen from "./Components/ResultDetailScreen";
import AdminWatchScreen from "./Components/Admin/AdminWatchScreen";
import AdminUserScreen from "./Components/Admin/AdminUserScreen";
import AdminDrawScreen from "./Components/Admin/AdminDrawScreen";
import Countdown from "./Components/Countdown";
import LoginTwitter from "./Components/LoginTwitter";
import LoginGoogle from "./Components/LoginGoogle";
import LoginMicrosoft from "./Components/LoginMicrosoft";
import PrivacyScreen from "./Components/PrivacyScreen";
import GalleryScreen from "./Components/Gallery/GalleryScreen";
import ReconnectTwitter from "./Components/ReconnectTwitter";
import InContestPresenterScreen from "./Components/InContestPresenterScreen";
import InstructionScreen from './Components/Admin/InstructionsScreen';
import { HttpTransportType } from '@microsoft/signalr';

let axiosSetup = new AxiosSetup();
let client = new ContestClient(undefined,
    axiosSetup.Create());

const SignalRContext = createSignalRContext();

interface IAppWithRoutes {
    year: ContestYear | undefined;
    authenticated: boolean;
    numberOfSongs: ContestNumberOfSongs | undefined;
    runningOrder: RunningOrder | undefined;
    resultsAvailable: ResultsAvailable | undefined;
    setPresenterMode(presenterMode: boolean) : void;
}

function AppWithRoutes(props: IAppWithRoutes) {
    if (props.authenticated) {

        return (
            <Routes>
                <Route path="/" element={<OverviewView year={props.year} runningOrder={props.runningOrder} resultsAvailable={props.resultsAvailable}/>} />
                <Route path="/Admin/Monitor" element={<AdminWatchScreen numberOfSongs={props.numberOfSongs}/>} />
                <Route path="/Admin/Users" element={<AdminUserScreen/>} />
                <Route path="/Admin/Result" element={<ResultPresenterScreen year={props.year} setPresenterMode={props.setPresenterMode}/>} />
                <Route path="/Admin/InContestPresenter" element={<InContestPresenterScreen runningOrder={props.runningOrder} setPresenterMode={props.setPresenterMode}/>} />
                <Route path="/Admin/Instructions" element={<InstructionScreen/>}/>
                <Route path="/Admin/Draw/:userId" element={<AdminDrawScreen year={props.year}/>} />
                <Route path="/Scoresheet/Contest/:runningOrder" element={<ScoresheetView/>} />
                <Route path="/User/Settings" element={<SettingsForm/>} />
                <Route path="/History" element={<HistoryScreen/>} />
                <Route path="/History/:year/:round" element={<HistoryContestScreen/>} />
                <Route path="/History/:year/:round/:rank" element={<HistoryDetailScreen/>} />
                <Route path="/Result" element={<ResultScreen year={props.year}/>} />
                <Route path="/Result/:rank" element={<ResultDetailScreen year={props.year}/>} />
                <Route path="/Home/Logout" element={<LogoutView/>}/>
                <Route path="/Home/Privacy" element={<PrivacyScreen/>}/>
                <Route path="/Twitter/:screenName/:refreshToken" element={<LoginTwitter/>}/>
                <Route path="/Google/:email/:refreshToken" element={<LoginGoogle/>}/>
                <Route path="/Microsoft/:email/:refreshToken" element={<LoginMicrosoft/>}/>
                <Route path="/Gallery" element={<GalleryScreen/>}/>
            </Routes>);
    }

    return (
        <Routes>
            <Route path="/Twitter/:screenName/:refreshToken" element={<LoginTwitter/>}/>
            <Route path="/Google/:email/:refreshToken" element={<LoginGoogle/>}/>
            <Route path="/Microsoft/:email/:refreshToken" element={<LoginMicrosoft/>}/>
            <Route path="/" element={<LoginView/>} />
            <Route path="/Home/Privacy" element={<PrivacyScreen/>}/>
        </Routes>);
}

function App() {
    const [
        isAuthenticated, setIsAuthenticated
    ] = useState(axiosSetup.Authenticated());

    const [
        isAdmin, setIsAdmin
    ] = useState(false);

    const[
        year, setYear
    ] = useState<ContestYear |
                 undefined>(undefined);

    const[
        resultsAvailable, setResultsAvaialable
    ] = useState<ResultsAvailable |
                 undefined>(undefined);

    const[
        numberOfSongs, setNumberOfSongs
    ] = useState<ContestNumberOfSongs |
                 undefined>(undefined);

    const[
        runningOrder, setRunningOrder
    ] = useState<RunningOrder |
                 undefined>(undefined);

    const[
        closeTime, setCloseTime
    ] = useState<ContestCloseTime |
                 undefined>(undefined);

    const[presenterMode, setPresenterMode] = useState(false);

    const[reconnect, setReconnect] = useState<boolean>(false);

    const[tweetingAs, setTweetingAs] = useState<string | undefined | null>(undefined);

    useEffect(() => {
            fetchStatus();
        },
        []);

    function fetchStatus() : void {
        const promise = client.status();

        promise.then(currentStatus => {
            if (!currentStatus.IsAuthenticated) {
                setIsAuthenticated(false);
                setIsAdmin(false);
                setYear(undefined);
                setNumberOfSongs(undefined);
                setRunningOrder(undefined);
                setCloseTime(undefined);
                setReconnect(false);
                setTweetingAs(undefined);

                return;
            }

            setIsAuthenticated(true);
            setIsAdmin(currentStatus.IsAdmin ?? false);
            setYear(currentStatus.Year);
            setResultsAvaialable(currentStatus.ResultsAvailable);
            setNumberOfSongs(currentStatus.NumberOfSongs);
            setRunningOrder(currentStatus.RunningOrder);
            setCloseTime(currentStatus.CloseTime);
            setReconnect(currentStatus.Reconnect ?? false);
            setTweetingAs(currentStatus.TweetingAs);
        }).catch(async ex => {
            if (isAuthenticated) {
                if (ex.status === 401 || ex.status === 403) {
                    await axiosSetup.Refresh();

                    fetchStatus();
                }
            }
        });
    }

    SignalRContext.useSignalREffect(
        "SetRunningOrder",
        (...args: any) => {
            setRunningOrder(args[0]);
        },
        []);

    SignalRContext.useSignalREffect(
        "StartCountdown",
        (...args: any) => {
            fetchStatus();
        },
        []);

    const signalRHub = `${process.env.REACT_APP_API_URL}api/SongScoreboardHub`;

    return (<SignalRContext.Provider url={signalRHub} transport={HttpTransportType.LongPolling}
                onOpen={() => console.log("open")}
                onBeforeClose={() =>
                    new Promise((resolve) => {
                      console.log("before close");
                      setTimeout(() => {
                        resolve();
                      }, 1000);
                    })
                  }
                onClosed={() => console.log("close", SignalRContext.connection?.state)}>
                <BrowserRouter>
                    <NavigationBar isAdmin={isAdmin} authenticated={isAuthenticated} year={year} resultsAvailable={resultsAvailable} presenterMode={presenterMode}/>
                    <Container fluid className="p-0 body-container pt-2">
                        <ReconnectTwitter reconnect={reconnect} tweetingAs={tweetingAs}/>
                        <Countdown closeTime={closeTime} resultsAvailable={resultsAvailable}/>
                        <AppWithRoutes year={year} authenticated={isAuthenticated} numberOfSongs={numberOfSongs} runningOrder={runningOrder} resultsAvailable={resultsAvailable} setPresenterMode={setPresenterMode}/>
                    </Container>
                </BrowserRouter>
            </SignalRContext.Provider>);
}

export default App;
