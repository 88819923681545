import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import AxiosSetup from "../api/AxiosSetup";
import {
    UserClient
} from "../Client";

let axiosSetup = new AxiosSetup();

var client = new UserClient(undefined,
    axiosSetup.Create());

interface IReconnectTwitterProps {
    reconnect: boolean | undefined | null;
    tweetingAs: string | undefined | null;
}

function ReconnectTwitter(props: IReconnectTwitterProps) {
    const [show, setShow] = useState<boolean>(true);

    function handleClose() {
        setShow(false);
        
        const url = process.env.REACT_APP_API_URL + "api/OAuth/Begin";

        window.location.href = url;
    }

    function handleDisconnect() {
        const promise = client.disconnect();

        promise.then(() => {
            setShow(false);
            window.location.reload();
        }).catch(async ex  => {
            if (ex.status === 401 || ex.status === 403) {
                await axiosSetup.Refresh();
            }

            handleDisconnect();
        });
    }


    if (props.reconnect === undefined || props.reconnect === null || !props.reconnect || props.tweetingAs === undefined || props.tweetingAs === null) {
        return(<React.Fragment/>);
    }

    return(<Modal show={show} onHide={handleClose} centered backdrop="static" keyboard={false}>
               <Modal.Header className="bg-danger text-white">
                   <Modal.Title>Thanks Elon!</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                    To continue tweeting as <b>@{props.tweetingAs}</b> you will need to re-authorize through Twitter.<br/><br/>
                    Select <b>Ok</b> to do that now.<br/><br/>
                    Selecting <b>Cancel</b> will disconnect you from Twitter - you can always reconnect through Settings.<br/><br/>
               </Modal.Body>
               <Modal.Footer  className="bg-danger">
                   <Button variant="light" onClick={handleDisconnect}>
                       Cancel
                   </Button>
                   <Button variant="success" onClick={handleClose}>
                       Ok
                   </Button>
               </Modal.Footer>
           </Modal>);
}

export default ReconnectTwitter;