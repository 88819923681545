import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import AxiosSetup from "../api/AxiosSetup";
import {
    IdentityClient,
    ScreenName2,
    RefreshTokenId
} from "../Client";

let axiosSetup = new AxiosSetup();

let client = new IdentityClient(undefined,
    axiosSetup.Create());

function LoginTwitter(): JSX.Element {
    const {
        screenName,
        refreshToken
    } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        var authRequest = {
            ScreenName: screenName as ScreenName2,
            RefreshToken: refreshToken as RefreshTokenId
        };

        const promise = client.twitter(authRequest);

        promise.then(jwt => {
                var redirectTo = "/";

                if (axiosSetup.Authenticated()) {
                    redirectTo = "/User/Settings";
                }

                localStorage.setItem("auth",
                    JSON.stringify(jwt));

                window.location.href = redirectTo;
            })
            .catch(ex => {
                navigate("/");
            });
    });

    return (<React.Fragment/>);
}

export default LoginTwitter;