import React, { useEffect, useState } from 'react';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import AxiosSetup from "../../api/AxiosSetup";
import {
    ScoresheetClient,
    SongClient,
    Song,
    ScoredCriteria,
    VotingOpen,
    CriteriaId,
    HashTag,
    Country,
    CriteriaValue,
    CountryCode,
    CommentText
} from "../../Client";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faClipboard } from "@fortawesome/free-solid-svg-icons";
import { Button, Form, Spinner, Toast, ToastContainer} from 'react-bootstrap';

let axiosSetup = new AxiosSetup();

let client = new ScoresheetClient(undefined, axiosSetup.Create());
let songClient = new SongClient(undefined,
    axiosSetup.Create());

interface IScoreTabProps {
    song: Song | undefined;
    scores: ScoredCriteria[] | null | undefined;
    votingOpen : VotingOpen | undefined;
    hashTags: HashTag[] | null | undefined;

    updateScores(song: Song | undefined, scoredCriteria: ScoredCriteria[]) : void;
}

interface IScoreLineProps {
    song: Song | undefined;
    children: ScoredCriteria | undefined;
    votingOpen: VotingOpen | undefined;
    updateScore(id : CriteriaId, value: number) : void;
}

interface IScoreTotals {
    Id: CriteriaId;
    Value: number;
    Multiplier: number;
}

interface ICommentViewProps {
    votingOpen: VotingOpen | undefined;
    hashTags: HashTag[] | null | undefined;
    song: Song | undefined;
}

interface IHashTagBasicProps {
    selected: boolean;
    handleClick(hashTagType: HashTagType, selected: boolean) : void;
}

interface IHashTagCountryProps extends IHashTagBasicProps {
    country: Country | undefined;
}

interface IHashTagCustomProps {
    hashTag: HashTag;
    selected: boolean;
    handleClick(hashTag: HashTag, selected: boolean) : void;
}

enum HashTagType {
    Eurovision = 1,
    TagLine = 2,
    CountryName = 3,
    CountryFlag = 4
}

function ScoreLineView(props: IScoreLineProps) {

    const [
        value, setValue
    ] = useState(props.children?.Value as number);

    useEffect(() =>
    {
        setValue(props.children?.Value as number);
    }, [props.children]);

    if (props.children === undefined) {
        return(<React.Fragment/>);
    }

    function handleChange(event : any) {
        var selectedOption = event.target.value;
        
        if (selectedOption === null) {
            return;
        }

        if (props.song === undefined || props.children === undefined) {
            return;
        }

        var workingValue = parseInt(selectedOption);

        const promise = client.score(props.song.Id as number,
            props.children.Id as number,
            workingValue);

        promise.then(() => {
            if (props.children === undefined || selectedOption === null || props.children.Id === undefined) {
                return;
            }

            setValue(workingValue);

            props.updateScore(props.children.Id,
                workingValue);
            })
            .catch(async ex => {
                if (ex.status === 401 || ex.status === 403) {
                    await axiosSetup.Refresh();
                    handleChange(event);
                }
            });
    }

    function dropDownOptions() : Array<JSX.Element> {
        const min = (props.children?.Min ?? 0) as number;
        const max = (props.children?.Max ?? 0) as number;

        const retVal = new Array<JSX.Element>();

        for (let x = min; x <= max; x++) {
            const key = `opt|${props.children?.Id}|${x}`;
            retVal.push(<option key={key} value={x}>{x}</option>)

        }

        return retVal;
    }

    if (props.votingOpen) {

        return (<div className="d-flex w-100 align-items-center">
                    <div className="p-2">
                        <span className="text-medium font-weight-bold">
                            {props.children.Description}
                        </span>
                    </div>
                    <div className="ms-auto p-2 text-medium text-number text-black">
                        <Form.Select onChange={handleChange} value={value}>
                            {dropDownOptions()}
                        </Form.Select>
                    </div>
                    <div className="p-2 text-small w-10">
                        <span className="text-nowrap" data-bind="text: MultiplierString">
                        x {props.children.Multiplier}
                        </span>
                    </div>
                    <div className="p-2 text-medium text-right text-number font-weight-bold w-15">
                        <span className="text-nowrap">
                            {(value as number) * (props.children.Multiplier as number)}
                        </span>
                    </div>
                </div>);
    }

    return (
        <div className="d-flex w-100 align-items-center">
            <div className="p-2">
                <span className="text-medium font-weight-bold">
                    {props.children.Description}
                </span>
            </div>
            <div className="ms-auto p-2 text-medium text-number text-white">
                {value}
            </div>
            <div className="p-2 text-small w-10">
                <span className="text-nowrap" data-bind="text: MultiplierString">
                    x {props.children.Multiplier}
                </span>
            </div>
            <div className="p-2 text-medium text-right text-number font-weight-bold w-15">
                <span className="text-nowrap">
                    {(value as number) * (props.children.Multiplier as number)}
                </span>
            </div>
        </div>);
}

function HashTagEurovision(props: IHashTagBasicProps) : JSX.Element {
    const [
        selected, setSelected
    ] = useState(props.selected);

    function handleClick(event: any) {
        const newState = !selected;
        
        setSelected(newState);

        props.handleClick(HashTagType.Eurovision, newState);
    }

    if (selected) {
        return (<span className="badge rounded-pill bg-success me-1" onClick={handleClick}>#Eurovision</span>);
    }

    return (<span className="badge rounded-pill bg-light text-dark me-1" onClick={handleClick}>#Eurovision</span>);
}

function HashTagTagLine(props: IHashTagBasicProps): JSX.Element {
    const [
        selected, setSelected
    ] = useState(props.selected);

    function handleClick(event: any) {
        const newState = !selected;
        
        setSelected(newState);

        props.handleClick(HashTagType.TagLine, newState);
    }

    if (selected) {
        return (<span className="badge rounded-pill bg-success me-1" onClick={handleClick}>#UnitedByMusic</span>);
    }

    return (<span className="badge rounded-pill bg-light text-dark me-1" onClick={handleClick}>#UnitedByMusic</span>);
}

function getCountryName(country: Country | undefined): string {
    if (country === undefined || country.Name === undefined) {
        return "#Unknown";
    }
        
    return `#${country.Name.toString().replace("F.Y.R. Macedonia",
            "FYRMacedonia")
        .replace("&",
            "")
        .replace(/ /g,
            "")}`;
}

function HashTagCountryName(props: IHashTagCountryProps): JSX.Element {
    const [
        selected, setSelected
    ] = useState(props.selected);

    const [
        hashTagText, setHashTagText
    ] = useState(getCountryName(props.country));

    function handleClick(event: any) {
        const newState = !selected;
        
        setSelected(newState);

        props.handleClick(HashTagType.CountryName, newState);
    }

    useEffect(() => {
            setHashTagText(getCountryName(props.country));
        },
        [
            props.country
        ]);

    if (selected) {
        return (<span className="badge rounded-pill bg-success me-1" onClick={handleClick}>{hashTagText}</span>);
    }

    return (<span className="badge rounded-pill bg-light text-dark me-1" onClick={handleClick}>{hashTagText}</span>);
}

function getCountryFlag(country: Country | undefined): string {
    if (country === undefined || country.Code === undefined || country.Code === null) {
        return "";
    }

    return country.Code["Emoji"].toString();
}

function HashTagCountryFlag(props: IHashTagCountryProps): JSX.Element {
    const [
        selected, setSelected
    ] = useState(props.selected);

    const [
        hashTagText, setHashTagText
    ] = useState(getCountryFlag(props.country));

    function handleClick(event: any) {
        const newState = !selected;
        
        setSelected(newState);

        props.handleClick(HashTagType.CountryFlag, newState);
    }


    useEffect(() => {
            setHashTagText(getCountryFlag(props.country));
        },
        [
            props.country
        ]);

    if (hashTagText === "") {
        return (<React.Fragment/>);
    }

    if (selected) {
        return (<span className="badge rounded-pill bg-success me-1" onClick={handleClick}>{hashTagText}</span>);
    }

    return (<span className="badge rounded-pill bg-light text-dark me-1" onClick={handleClick}>{hashTagText}</span>);
}


function HashTagCustom(props: IHashTagCustomProps) : JSX.Element {
    const [
        selected, setSelected
    ] = useState(props.selected);

    function handleClick(event: any) {
        const newState = !selected;
        
        setSelected(newState);

        props.handleClick(props.hashTag, newState);
    }

    if (selected) {
        return (<span className="badge rounded-pill bg-success me-1" onClick={handleClick}>{props.hashTag}</span>);
    }

    return (<span className="badge rounded-pill bg-light text-dark me-1" onClick={handleClick}>{props.hashTag}</span>);
}

interface ICommentButtonProps {
    makingComment: boolean;
    makeComment(event: any) : void;
}

function CommentView(props: ICommentViewProps) : JSX.Element {
    const [
        systemHashTags, setSystemHashTags
    ] = useState(getSystemHashTags());
    
    const [
        customHashTags, setCustomHashTags
    ] = useState(getCustomHashTags());

    const [
        comment, setComment
    ] = useState("");

    const [
        makingComment, setMakingComment
    ] = useState(false);

    const [commentCycleComplete, setCommentCycleComplete] = useState(false);
    const [lastComment, setLastComment] = useState("");

    useEffect(() => {
            setComment("");
        },
        [
            props.song
        ]);

    if (!props.votingOpen) {
        return(<React.Fragment/>);
    }

    function handleHashTagToggle(hashTagType: HashTagType,
        selected: boolean) {

        const work = systemHashTags;

        work[hashTagType] = selected;

        setSystemHashTags(work);
    }

    function handleCustomHashTagToggle(hashTag: HashTag, selected: boolean) {
        const work = customHashTags;

        for (let i = 0; i < work.length; i++) {
            if (work[i].hashTag === hashTag) {
                work[i].selected = selected;
            }
        }

        setCustomHashTags(work);
    }

    function getSystemHashTags():  { [key: number]: boolean; } {
        const retVal = {
            [HashTagType.Eurovision] : false,
            [HashTagType.TagLine] : false,
            [HashTagType.CountryName] : false,
            [HashTagType.CountryFlag] : false
        };

        if (props.hashTags !== undefined && props.hashTags !== null) {
            for (let i = 0; i < props.hashTags.length; i++) {
                const hashTag = props.hashTags[i].toString();

                if (hashTag === "#Eurovision") {
                    retVal[HashTagType.Eurovision] = true;
                }

                if (hashTag === "##Tagline##") {
                    retVal[HashTagType.TagLine] = true;
                }

                if (hashTag === "##Country##") {
                    retVal[HashTagType.CountryName] = true;
                    retVal[HashTagType.CountryFlag] = true;
                }
            }
        }

        return retVal;
    }

    function getCustomHashTags(): Array<{ hashTag: HashTag, selected: boolean }> {
        const retVal = new Array<{hashTag: HashTag; selected: boolean }>();

        if (props.hashTags !== undefined && props.hashTags !== null) {
            for (let i = 0; i < props.hashTags.length; i++) {
                const hashTag = props.hashTags[i];

                const hashTagAsString = hashTag.toString();

                if (hashTagAsString === "#Eurovision") {
                    continue;
                }

                if (hashTagAsString === "##Tagline##") {
                    continue;
                }

                if (hashTagAsString === "##Country##") {
                    continue;
                }

                retVal.push({
                    hashTag: hashTag,
                    selected: true
                });
            }
        }

        return retVal;
    }

    function hashTags(): Array<JSX.Element> {
        if (customHashTags === null || customHashTags === undefined) {
            return new Array<JSX.Element>();
        }

        const retVal = new Array<JSX.Element>();

        for (let i = 0; i < customHashTags.length; i++) {
            const hashTag = customHashTags[i];
            
            retVal.push(<HashTagCustom hashTag={hashTag.hashTag} handleClick={handleCustomHashTagToggle} selected={hashTag.selected} key={i}/>)
        }

        return retVal;
    }

    function makeComment(event: any): void {
        if (props.song === undefined || props.song.Id === undefined || comment.trim() === "") {
            setComment("");
            return;
        }

        setMakingComment(true);

        var commentToMake = commentText();

        const promise = songClient.comment(props.song.Id as number,
            commentToMake);

        promise.then(() => {
            setComment("");
            setMakingComment(false);
            setCommentCycleComplete(true);
            setLastComment(commentToMake);
        }).catch(async ex => {
            if (ex.status === 401 || ex.status === 403) {
                await axiosSetup.Refresh();
            }
            makeComment(event);
        });
    }

    function commentText() : string {
        if (props.song === undefined || props.song.Id === undefined || comment.trim() === "") {
            return "";
        }

        let commentToMake = `${comment}\r\n\r\n`;

        var hashTags = "";

        if (systemHashTags[HashTagType.Eurovision]) {
            hashTags += " #Eurovision";
        }

        if (systemHashTags[HashTagType.TagLine]) {
            hashTags += " #UnitedByMusic";
        }

        if (systemHashTags[HashTagType.CountryName]) {
            hashTags += ` ${getCountryName(props.song.Country)}`;
        }

        if (systemHashTags[HashTagType.CountryFlag]) {
            hashTags += ` ${getCountryFlag(props.song.Country)}`;
        }

        for (let i = 0; i < customHashTags.length; i++) {
            const customHashTag = customHashTags[i];

            if (customHashTag.selected) {
                hashTags += ` ${customHashTag.hashTag}`;
            }
        }

        return commentToMake + hashTags.trim();
    }

    if (props.song === undefined || props.song.Country === undefined) {
        return(<React.Fragment/>);
    }

    var commentButtonCss = makingComment ? "btn btn-primary btn-sm disabled" : "btn btn-primary btn-sm";
    var commentButtonIcon = makingComment ? <Spinner animation="border" size="sm" as="span" role="status"/> : <FontAwesomeIcon icon={faBullhorn}/>;
    var commentButtonText = makingComment ? "Commenting..." : "Comment";

    return (<div className="d-flex w-100 mt-auto">
                <div className="d-flex flex-column align-items-start w-100">
                    <div className="pl-2 pr-2 pt-2 w-100">
                        <Form.Control as="textarea" rows={3} cols={50} className="contest-detail-comment" autoCapitalize="sentences" autoComplete="off" value={comment} onChange={evt => setComment(evt.target.value)} disabled={makingComment}/>
                    </div>
                    <div className="ps-2 pe-2 w-100 text-medium d-none d-md-inline mt-1">
                        <HashTagEurovision handleClick={handleHashTagToggle} selected={systemHashTags[HashTagType.Eurovision]}/>
                        <HashTagTagLine handleClick={handleHashTagToggle} selected={systemHashTags[HashTagType.TagLine]}/>
                        <HashTagCountryName handleClick={handleHashTagToggle} selected={systemHashTags[HashTagType.CountryName]} country={props.song.Country}/>
                        <HashTagCountryFlag handleClick={handleHashTagToggle} selected={systemHashTags[HashTagType.CountryFlag]} country={props.song.Country}/>
                        {hashTags()}
                    </div>
                    <div className="ps-2 pe-2 w-100 text-large d-inline d-md-none">
                        {hashTags()}
                    </div>

                    <div className="ps-2 pe-2 pb-2 pt-2 w-100 text-end d-inline d-md-none">
                        <div className="btn btn-primary btn-lg" onClick={makeComment}>
                            &nbsp;Comment
                        </div>
                    </div>
                    <div className="p-2 w-100 text-end d-none d-md-inline">
                        <CopyToClipboard text={commentText()} onCopy={makeComment}>
                            <div className={commentButtonCss}>
                                {commentButtonIcon}&nbsp;{commentButtonText}
                            </div>
                        </CopyToClipboard>                        
                    </div>
                </div>
                <ToastContainer position="top-center">
                    <Toast show={commentCycleComplete} autohide delay={3000} onClose={() => setCommentCycleComplete(false)} bg="dark">
                        <Toast.Header><span className="me-auto">Copied to clipboard.&nbsp;<FontAwesomeIcon icon={faClipboard}/></span></Toast.Header>
                        <Toast.Body>
                            <span dangerouslySetInnerHTML={{__html: lastComment.replaceAll("\r\n", "<br/>")}}/>
                        </Toast.Body>
                    </Toast>
                </ToastContainer>                        
            </div>);
}

function ScoreTab(props: IScoreTabProps) {
    const[
        scores, setScores
    ] = useState(toIScoreTotals());

    const [
        total, setTotal
    ] = useState(calculateTotal());

    function items(): Array<JSX.Element> {
        if (props.scores === undefined || props.scores === null) {
            return new Array<JSX.Element>();
        }

        return props.scores.map(score => {
            var key = `SCR|${score.Id}`;

            return (<ScoreLineView key={key} votingOpen={props.votingOpen} updateScore={updateFromChild} song={props.song}>{score}</ScoreLineView>);
        });
    }

    useEffect(() => {
            setScores(toIScoreTotals());
        },
        [
            props.scores
        ]);

    useEffect(() => {
            updateTotal();
        },
        [
            scores
        ]);

    function updateFromChild(criteriaId: CriteriaId, value: number) {
        if (scores === undefined || scores === null) {
            return;
        }
        
        const workingScores = scores;


        for (let i = 0; i < workingScores.length; i++) {
            const candidate = workingScores[i];

            if (candidate.Id === criteriaId) {
                candidate.Value = value;
                break;
            }
        }

        setScores(workingScores);

        updateTotal();

        props.updateScores(props.song,
            toScoredCriteria());
    }

    function updateTotal() {
        setTotal(calculateTotal());
    }

    function calculateTotal(): number {
        let retVal = 0;
        for (let i = 0; i < scores.length; i++) {
            retVal += scores[i].Value * scores[i].Multiplier;
        }
        return retVal;
    }

    function toIScoreTotals(): Array<IScoreTotals> {
        if (props.scores === undefined || props.scores === null) {
            return new Array<IScoreTotals>();
        }

        const retVal = new Array<IScoreTotals>();

        for (let i = 0; i < props.scores.length; i++) {
            const work = props.scores[i];

            if (work.Id === undefined || work.Id === null) {
                continue;
            }

            retVal.push({
                Id: work.Id,
                Value: work.Value as number,
                Multiplier: work.Multiplier as number
            });
        }

        return retVal;
    }

    function toScoredCriteria(): Array<ScoredCriteria> {
        const retVal = new Array<ScoredCriteria>();

        if (props.scores !== undefined && props.scores !== null) {
            for (let i = 0; i < scores.length; i++) {
                const outputScore = scores[i];

                for (let x = 0; x < props.scores.length; x++) {
                    const propsCandidate = props.scores[x];

                    if (outputScore.Id === propsCandidate.Id) {
                        retVal.push({
                            Id: propsCandidate.Id,
                            Description: propsCandidate.Description,
                            Multiplier: propsCandidate.Multiplier,
                            Min: propsCandidate.Min,
                            Max: propsCandidate.Max,
                            Weighting: propsCandidate.Weighting,
                            Order: propsCandidate.Order,
                            Value: toCriteriaValue(outputScore.Value)
                        });

                        break;
                    }
                }
            }
        }

        return retVal;
    }

    function toCriteriaValue(value: number): CriteriaValue {
        return value as CriteriaValue;
    }

    return (<div className="d-flex flex-column align-items-start h-100">
                {items()}
                <div className="d-flex w-100 align-items-center text-large">
                    <div className="ms-auto p-2 text-number fw-bolder">
                        <span className="text-nowrap text-number fw-bolder text-total">
                            {total}
                        </span>
                    </div>
                </div>
                <CommentView votingOpen={props.votingOpen} hashTags={props.hashTags} song={props.song}></CommentView>
            </div>);
}

export default ScoreTab;