import React from 'react';

import {
    Song,
    Country,
    RunningOrder,
    Person,
    ContestYear,
    Rank
} from "../../Client";

import FlagSmall from "../Country/FlagSmall";


interface IArtistPaneProps {
    country : Country | undefined;
    song: Song | undefined;
    runningOrder: RunningOrder | Rank | undefined;
    year: ContestYear | undefined;
    includeSongInformation: boolean;
}

interface IPeopleProps {
    children: Person[] | null | undefined;
    keyPrefix: string;
}

interface ISongProps {
    children: Song | undefined;
}

function People(props: IPeopleProps) : JSX.Element {
    function items(): Array<JSX.Element> {
        if (props.children === undefined || props.children === null) {
            return new Array<JSX.Element>();
        }

        return props.children.map(person => {
            var key = props.keyPrefix + "|" + person.Id;

            return (<li key={key}>{person.Name}</li>);
        });
    }

    return (<ul className="list-unstyled">{items()}</ul>);
}

function ComposersAndLyricistsHeader(props: ISongProps) : JSX.Element {
    const composers = props.children?.Composers?.length === 1
        ? "Composer"
        : "Composers";

    const lyricists = props.children?.Lyricists?.length === 1
        ? "Lyricist"
        : "Lyricists";

    return(<div className="d-flex w-100 text-white text-small justify-content-between scoresheet-details-header">
               <div className="ps-1 pt-1 pb-0">
                   <span className="fw-bold">{composers}</span>
               </div>
               <div className="pe-1 pt-1 pb-0">
                   <span className="fw-bold">{lyricists}</span>
               </div>
           </div>);
}

function FindStyle(song: Song | undefined, key: string, defaultValue: string): string {
    if (song === undefined || song.Extended === undefined || song.Extended === null) {
        return defaultValue;
    }

    for (var i = 0; i < song.Extended.length; i++) {
        var kvp = song.Extended[i];

        if (kvp.Key == key) {
            return kvp.Value as string;
        }
    }

    return defaultValue;
}

function ArtistPane(props: IArtistPaneProps) : JSX.Element {
    const countryName = props.country?.Name as string;

    var backgroundPositionY = FindStyle(props.song, "backgroundPositionY", "50%");
    var backgroundPositionX = FindStyle(props.song, "backgroundPositionX", "50%");
    var backgroundSize = FindStyle(props.song, "backgroundSize", "cover");
    var height = FindStyle(props.song, "height", "80vh");

    const style = {
        top: "0",
        left: "0",
        width: "100%",
        height: height,
        backgroundImage: `url(${process.env.REACT_APP_API_URL}api/Song/${props.song?.Id}/Img),url(${process.env.REACT_APP_API_URL}api/Song/${props.song?.Id}/Blurred)`,
        backgroundPositionX: backgroundPositionX,
        backgroundPositionY: backgroundPositionY,
        backgroundSize: backgroundSize,
        backgroundRepeat: "no-repeat, repeat",
        backgroundColor: "rgba(0, 0, 0, 0.3)",
        zIndex: "-1"
    } as React.CSSProperties;

    if (props.includeSongInformation) {
        return (
            <div className="w-100">
                <div className="artist-pane">
                    <div className="d-flex flex-row text-white mb-auto scoresheet-country-header">
                        <div className="p-2 ps-4 flex-grow-1 align-self-center">
                            <span className="text-large fw-bold text-nowrap">{props.runningOrder}</span>
                        </div>
                        <div className="ms-auto p-2 align-self-center">
                            <span className="text-large fw-bold">{countryName}</span>
                        </div>
                        <div className="p-2">
                            <FlagSmall country={props.country} year={props.year} css={undefined}/>
                        </div>
                    </div>
                    <div className="d-flex flex-column align-items-start align-items-center mt-auto scoresheet-details-header">
                        <div className="d-flex w-100 justify-content-center text-uppercase text-white">
                            <div className="pt-1 pb-1">
                                <span className="text-large fw-bold">{props.song?.Artist?.Name}</span>
                            </div>
                        </div>
                        <div className="d-flex w-100 text-white justify-content-between scoresheet-details-header">
                            <div className="ps-2 ps-1 pt-1 pb-1">
                                <span className="text-large fw-bold">{props.song?.Title}</span>
                            </div>
                            <div className="pe-3 pt-1">
                                <span className="text-large">{props.song?.Translation}</span>
                            </div>
                        </div>
                        <ComposersAndLyricistsHeader>{props.song}</ComposersAndLyricistsHeader>
                        <div className="d-flex w-100 text-white text-medium justify-content-between scoresheet-details-header">
                            <div className="ps-2 pt-1 pb-1">
                                <People keyPrefix="composers">{props.song?.Composers}</People>
                            </div>
                            <div className="pe-2 pt-1 pb-1 text-end">
                                <People keyPrefix="lyricists">{props.song?.Lyricists}</People>
                            </div>
                        </div>
                    </div>
                    <div style={style}>
                    </div>
                </div>
            </div>);
    }

    return (
        <div className="w-100">
            <div className="artist-pane">
                <div className="d-flex flex-row text-white mb-auto scoresheet-country-header">
                    <div className="p-2 ps-4 flex-grow-1 align-self-center">
                        <span className="text-large fw-bold text-nowrap">{props.runningOrder}</span>
                    </div>
                    <div className="ms-auto p-2 align-self-center">
                        <span className="text-large fw-bold">{countryName}</span>
                    </div>
                    <div className="p-2">
                        <FlagSmall country={props.country} year={props.year} css={undefined}/>
                    </div>
                </div>
                <div style={style}>
                </div>
            </div>
        </div>);
}

export default ArtistPane;