import React from 'react';


function PrivacyScreen(): JSX.Element {
    return (
        <div className="card glass privacy-policy-container">
            <div className="card-header text-white"><h2>Beavervision Privacy Policy</h2></div>
            <div className="text-white p-2">
                This privacy policy will explain how our organization uses the personal data we collect from you when you use our website.

                <h3>Topics</h3>

                What data do we collect?<br/>
                How do we collect your data?<br />
                How will we use your data?<br />
                How do we store your data?<br />
                Marketing<br />
                What are your data protection rights?<br />
                What are cookies?<br />
                How do we use cookies?<br />
                What types of cookies do we use?<br />
                How to manage your cookies<br />
                Privacy policies of other websites<br />
                Changes to our privacy policy<br />
                How to contact us<br />
                How to contact the appropriate authorities<br />
                What data do we collect?<br />

                <h3>We collect the following data</h3>

                Personal identification information (Name etc.)

                <h3>How do we collect your data?</h3>

                You directly provide Beavervision with most of the data we collect. We collect data and process data when you:<br />

                Register online or place an order for any of our products or services.<br />
                Use or view our website via your browser’s cookies.<br />

                <h3>How will we use your data?</h3>

                Beavervision collects your data so that we can:<br />

                Manage your account.

                <h3>How do we store your data?</h3>

                Beavervision securely stores your data.

                <h3>What are your data protection rights?</h3>

                Beavervision would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:<br />

                The right to access – You have the right to request Beavervision for copies of your personal data. We may charge you a small fee for this service.<br />

                The right to rectification – You have the right to request that Beavervision correct any information you believe is inaccurate. You also have the right to request Beavervision to complete the information you believe is incomplete.<br />

                The right to erasure – You have the right to request that Beavervision erase your personal data, under certain conditions.<br />

                The right to restrict processing – You have the right to request that Beavervision restrict the processing of your personal data, under certain conditions.<br />

                The right to object to processing – You have the right to object to Beavervision’s processing of your personal data, under certain conditions.<br />

                The right to data portability – You have the right to request that Beavervision transfer the data that we have collected to another organization, or directly to you, under certain conditions.<br />

                If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: admin@songscoreboard.com<br />

                <h3>Cookies</h3>

                Cookies are text files placed on your computer to collect standard Internet log information and visitor behavior information. When you visit our websites, we may collect information from you automatically through cookies or similar technology<br />

                For further information, visit allaboutcookies.org.<br />

                <h3>How do we use cookies?</h3>

                Beavervision uses cookies in a range of ways to improve your experience on our website, including:<br />

                Keeping you signed in<br />
                Remembering your settings<br />

                <h3>What types of cookies do we use?</h3>

                There are a number of different types of cookies, however, our website uses:<br />

                Functionality – Beavervision uses these cookies so that we recognize you on our website and remember your previously selected preferences. These could include what language you prefer and location you are in.<br />

                <h3>How to manage cookies</h3>
                You can set your browser not to accept cookies, and the above website tells you how to remove cookies from your browser. However, in a few cases, some of our website features may not function as a result.

                <h3>Privacy policies of other websites</h3>
                The Beavervision website contains links to other websites. Our privacy policy applies only to our website, so if you click on a link to another website, you should read their privacy policy.

                <h3>Changes to our privacy policy</h3>
                Beavervision keeps its privacy policy under regular review and places any updates on this web page. This privacy policy was last updated on 22nd April 2022.
            </div>
        </div>
    );
}

export default PrivacyScreen;