import React, { useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import AxiosSetup from "../api/AxiosSetup";
import {
    IdentityClient,
    UserEmail,
    RefreshTokenId
} from "../Client";

let axiosSetup = new AxiosSetup();

let client = new IdentityClient(undefined,
    axiosSetup.Create());

function LoginGoogle(): JSX.Element {
    const {
        email,
        refreshToken
    } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        var authRequest = {
            Email: email as UserEmail,
            RefreshToken: refreshToken as RefreshTokenId
        };

        const promise = client.google(authRequest);

        promise.then(jwt => {
                var redirectTo = "/";

                localStorage.setItem("auth",
                    JSON.stringify(jwt));

                window.location.href = redirectTo;
            })
            .catch(ex => {
                navigate("/");
            });
    });

    return (<React.Fragment/>);
}

export default LoginGoogle;