import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import AxiosSetup from "../api/AxiosSetup";
import { useNavigate } from "react-router-dom";

import {
        HistoryClient,
        ContestIndex
} from "../Client";

import ContestLogo from "./Contest/ContestLogo";

let axiosSetup = new AxiosSetup();

var client = new HistoryClient(undefined,
    axiosSetup.Create());

interface IHistoryItemProps {
    contests: ContestIndex[];
}

interface IContestItemProps {
    contest: ContestIndex;
}

function ContestItem(props: IContestItemProps): JSX.Element {
    const navigate = useNavigate();

    if (props.contest.ShortTitle === undefined || props.contest.Round === undefined || props.contest.Year === undefined) {
        return (<React.Fragment/>);
    }

    let buttonText = props.contest.ShortTitle.toString();


    if (buttonText === "") {
        buttonText = props.contest.Round.toString();
    }

    var route = `/History/${props.contest.Year.toString()}/${props.contest.Round.toString()}`;

    return (<Button variant="info" className="m-1" onClick={() => navigate(route)}>
            {buttonText}           
            </Button>);
}

function HistoryItem(props: IHistoryItemProps): JSX.Element {
    const year = props.contests[0].Year;
    
    function items() {
        return props.contests.map(contest => {
            var key = contest.Id?.toString();

            return (<ContestItem key={key} contest={contest}/>);
        });
    }

    const largeCss = `rounded d-none d-md-block d-lg-block d-xl-block logo-${year}`;
    const smallCss = `rounded d-block d-md-none d-lg-none d-xl-none logo-${year}`;

    return (
        <div className="card glass">
            <div className="d-flex justify-content-center m-1">
                <span className="text-large text-white">{year}</span>
            </div>
            <div className="d-flex justify-content-center m-1">
                <ContestLogo year={year} size={120} cssClass={largeCss}/>
                <ContestLogo year={year} size={75} cssClass={smallCss}/>
            </div>
            <div className="d-flex justify-content-center m-2">
                {items()}
            </div>
        </div>
    );
}

function HistoryScreen() : JSX.Element {
    const [
        history, setHistory
    ] = useState<ContestIndex[] |
                 undefined>(undefined);


    const[
        loaded, setLoaded
    ] = useState(false);

    useEffect(() => {
        if (loaded) {
            return;
        }

        const promise = client.history();

        promise.then(work => {
                setHistory(work);
                setLoaded(true);
            })
            .catch(async ex => {
                if (ex.status === 401 || ex.status === 403) {
                    await axiosSetup.Refresh();
                }
            });
            
    }, []);

    if (history === undefined) {
        return (<React.Fragment/>);
    }

    function items(): Array<JSX.Element> {
        if (history === undefined) {
            return new Array<JSX.Element>();
        }

        const dict: {
            [key: string]: Array<ContestIndex>
        } = {};

        for (let i = 0; i < history.length; i++) {
            const item : ContestIndex = history[i];

            if (item.Year === undefined) {
                continue;
            }

            const year = item.Year.toString();

            if (!(year in dict)) {
                dict[year] = new Array<ContestIndex>();
            }

            dict[year].push(item);
        }

        const retVal = new Array<JSX.Element>();

        for (let key in dict) {
            const value = dict[key];

            retVal.push(<div className="float-start m-1" key={key}><HistoryItem contests={value}/></div>);
        }

        return retVal;
    }

    return (<div className="d-flex flex-wrap justify-content-center">
                {items()}
            </div>);
}

export default HistoryScreen;