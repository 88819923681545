import React from 'react';

import {
    FrameCurrentVote,
    ContestYear,
} from "../../Client";

import FlagMedium from "../Country/FlagMedium";

interface ICurrentScoreProps {
    frame: FrameCurrentVote;
    latest: boolean;
    year: ContestYear | undefined;
}

function CurrentScore(props: ICurrentScoreProps): JSX.Element {
    if (!props.latest) {
        return(<li>
                   <div className="presenter-sequence-eurovision strokeme me-1">
                       {props.frame.Points?.toString()}
                   </div>
                   <FlagMedium year={props.year} country={props.frame.Country} css="me-2"/>
               </li>);
    }

    return(<li>
               <div className="presenter-sequence-eurovision strokeme me-1 animate__animated animate__slideInLeft">
                   {props.frame.Points?.toString()}
               </div>
               <FlagMedium year={props.year} country={props.frame.Country} css="me-2 animate__animated animate__rollIn"/>
           </li>);
}

export default CurrentScore;