import React, { useEffect, useState } from 'react';
import { NavigateFunction, useNavigate } from "react-router-dom";
import AxiosSetup from "../api/AxiosSetup";
import CountryItem from "./Contest/CountryItem"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShare } from "@fortawesome/free-solid-svg-icons";

import {
    ContestClient,
    ScoresheetClient,
    Scoreboard,
    ContestYear,
    RankedCountry,
    Rank
} from "../Client";
import { Button, Modal } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';

let axiosSetup = new AxiosSetup();

let client = new ContestClient(undefined, axiosSetup.Create());
let ssClient = new ScoresheetClient(undefined, axiosSetup.Create());

interface IResultScreenProps {
    year: ContestYear | undefined;
}

interface IResultsModalProps {
    show: boolean;
    handleClose() : void;
    navigate: NavigateFunction;
}

function PointsFromRank(rank : Rank) : number
{
    switch (rank)
    {
        case 1:
            return 12;
        case 2:
            return 10;
        case 3:
            return 8;
        case 4:
            return 7;
        case 5:
            return 6;
        case 6:
            return 5;
        case 7:
            return 4;
        case 8:
            return 3;
        case 9:
            return 2;
        case 10:
            return 1;
    }

    return 0;
}


function ResultsModal(props : IResultsModalProps) {
    const[shareText, setShareText] = useState("");

    useEffect(() => {
        const promise = ssClient.ranked();

        promise.then(ranked => {
            if (ranked !== undefined) {
                if (ranked.length !== 0) {
                    var work  = "My #Eurovision points:\r\n";

                    for(var i = 0; i < ranked.length; i++)
                    {
                        var rankedCountry = ranked[i];

                        if(rankedCountry.Country === null || rankedCountry.Country === undefined || rankedCountry.Country.Code === null || rankedCountry.Country.Code === undefined)
                        {
                            continue;
                        }

                        if(rankedCountry.Rank === null || rankedCountry.Rank === undefined)
                        {
                            continue;
                        }

                        var points = PointsFromRank(rankedCountry.Rank);

                        var toAdd = `\r\n${points}pt${points == 1 ? "" : "s"} ${rankedCountry.Country?.Code["Emoji"]} ${rankedCountry.Country.Name}`;

                        work += toAdd;
                    }

                    setShareText(work);

                }
                return;
            }

            props.navigate("/");
        }).catch(async ex  => {
            if (ex.status === 401 || ex.status === 403) {
                await axiosSetup.Refresh();
            }

            props.navigate("/");
        });
    },
    [props]);
        

    return(<Modal show={props.show} onHide={props.handleClose} centered backdrop="static" keyboard={false}>
               <Modal.Header className="bg-secondary text-white">
                   <Modal.Title>My Results</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                   <span dangerouslySetInnerHTML={{__html: shareText.replaceAll("\r\n", "<br/>")}}/>
               </Modal.Body>
               <Modal.Footer  className="bg-secondary">
                   <Button variant="light" onClick={props.handleClose}>
                       Cancel
                   </Button>
                   <CopyToClipboard text={shareText} onCopy={props.handleClose}>
                    <Button variant="success">
                        Copy To Clipboard
                    </Button>
                   </CopyToClipboard>
               </Modal.Footer>
           </Modal>);
}

function ResultScreen(props: IResultScreenProps): JSX.Element {
    const navigate = useNavigate();

    const[
        scoreboard, setScoreboard
    ] = useState<Scoreboard[] |
                 undefined>(undefined);

    const[
        loaded, setLoaded
    ] = useState(false);

    const[
        breakPoint, setBreakPoint
    ] = useState(0);

    const[showModal, setShowModal] = useState(false);

    useEffect(() => {
            if (loaded) {
                return;
            }

            if (props.year === undefined) {
                return;
            }

            const promise = client.result();

            promise.then(scoreboard => {
                if (scoreboard !== undefined) {
                    if (scoreboard.length === 0) {
                        navigate("/");
                    }
                    
                     setBreakPoint(scoreboard.length % 2 === 0 ? (scoreboard.length / 2) : ((scoreboard.length + 1) / 2));
                    
                    setScoreboard(scoreboard);

                    setLoaded(true);
                }
            }).catch(async ex  => {
                if (ex.status === 401 || ex.status === 403) {
                    await axiosSetup.Refresh();
                }

                navigate("/");
            });
        },
        [props]);

    function handleClick(item: Scoreboard) {
        navigate(`/Result/${item.Rank}`);
    }

    function items(): Array<JSX.Element> {
        if (scoreboard === undefined) {
            return new Array<JSX.Element>();
        }

        return scoreboard.map(item => {
            var key = `RESULT|${props.year}|${item.Rank}`;

            return (<CountryItem key={key} scoreboard={item} year={props.year} handleClick={handleClick}/>);
        });
    }

    function firstHalf(): Array<JSX.Element> {
        if (scoreboard === undefined) {
            return new Array<JSX.Element>();
        }

        const retVal = new Array<JSX.Element>();

        for (let i = 0; i < breakPoint; i++) {
            const item = scoreboard[i];
            const key = `RESULT|${props.year}|${item.Rank}`;

            retVal.push(<CountryItem key={key} scoreboard={item} year={props.year} handleClick={handleClick}/>);
        }

        return retVal;
    }

    function secondHalf(): Array<JSX.Element> {
        if (scoreboard === undefined) {
            return new Array<JSX.Element>();
        }

        const retVal = new Array<JSX.Element>();

        for (let i = breakPoint; i < scoreboard.length; i++) {
            const item = scoreboard[i];
            const key = `RESULT|${props.year}|${item.Rank}`;

            retVal.push(<CountryItem key={key} scoreboard={item} year={props.year} handleClick={handleClick}/>);
        }

        return retVal;
    }

    return (
    <div className="m-1">
        <div className="d-flex flex-wrap justify-content-center w-100">
            <h2 className="text-light">{props.year}</h2>
        </div>
        <div className="row d-none d-xl-flex d-lg-flex mt-lg-1 me-0">
            <div className="col-6">
                <div className="card border-light">
                    <ul className="list-group list-group-flush text-dark">
                        {firstHalf()}
                    </ul>
                </div>
            </div>
            <div className="col-6 ">
                <div className="card border-light">
                    <ul className="list-group list-group-flush text-dark">
                        {secondHalf()}
                    </ul>
                </div>
            </div>
        </div>
        <div className="row d-flex d-lg-none d-xl-none mt-0 me-0">
            <div className="col-12">
                <div className="card border-light">
                    <ul className="list-group list-group-flush text-dark">
                        {items()}
                    </ul>
                </div>
            </div>
        </div>
        <div className="row">
            &nbsp;
        </div>
        <div className="d-flex flex-wrap justify-content-end w-100 p-3">
            <Button onClick={() => setShowModal(true)} variant="success">
                <FontAwesomeIcon icon={faShare}/>&nbsp;Share My Results
            </Button>
            <ResultsModal show={showModal} handleClose={() => setShowModal(false)} navigate={navigate}/>
        </div>
    </div>);

}

export default ResultScreen;