import React, { useEffect, useState } from 'react';
import AxiosSetup from "../../api/AxiosSetup";
import { useLocation } from "react-router";

import FlagSmall from "../Country/FlagSmall";

import {
    CommentMediaClient,
    SongCommentMedia,
    ContestYear
} from "../../Client";

let axiosSetup = new AxiosSetup();

var client = new CommentMediaClient(undefined,
    axiosSetup.Create());

interface IGalleryItemProps {
    songComment: SongCommentMedia;
}

function GalleryItem(props: IGalleryItemProps): JSX.Element {
    if (props.songComment.Song === undefined || props.songComment.Song.Country === undefined || props.songComment.User === undefined || props.songComment.Text === undefined || props.songComment.Id === undefined) {
        return (<React.Fragment/>);
    }

    var image = `${process.env.REACT_APP_API_URL}api/CommentMedia/${props.songComment.Id}/Image`;
    var comment = props.songComment.Text.toString();
    var id = props.songComment.Id.toString();


    return (
        <li id={id}>
            <div className="card glass text-white m-5">
                <div className="card-header">
                    <div className="d-flex w-100 align-items-center image-overlay-pane text-white mb-auto">
                        <div className="p-2">
                            <span className="text-large fw-bold text-nowrap">{props.songComment.Year}</span>
                        </div>
                        <div className="p-2">
                            <FlagSmall country={props.songComment.Song.Country} year={props.songComment.Year} css={undefined}/>
                        </div>
                        <div className="p-2 d-none d-md-block">
                            <span className="text-large fw-bold">{props.songComment.Song.Country.Name}</span>
                        </div>
                        <div className="ms-auto p-2">
                            <span className="text-large fw-bold">{props.songComment.User.Name}</span>
                        </div>
                    </div>
                </div>
                <div className="card-body text-large mx-auto d-block text-center">
                    <div>
                        <img src={image} alt={comment} loading="lazy" className="rounded img-fluid gallery-timeline-img"/>
                    </div>
                    <p className="gallery-timeline-comment">{comment}</p>
                </div>
            </div>
        </li>
        );
}

function GalleryScreen(): JSX.Element {
    const [
        songComments, setSongComments
    ] = useState<SongCommentMedia[] |
                 undefined>(undefined);

    const[
        loaded, setLoaded
    ] = useState(false);

    const { hash, key } = useLocation();

    useEffect(() => {
        if (loaded) {
            return;
        }

        const promise = client.all();

        promise.then(work => {
                setSongComments(work);
                setLoaded(true);
            })
            .catch(async ex => {
                if (ex.status === 401 || ex.status === 403) {
                    await axiosSetup.Refresh();
                }
            });
            
    }, []);

    useEffect(() => {
            if (hash) {
                var deHashed = hash.substring(1);
                const targetElement = document.getElementById(deHashed);
                targetElement?.scrollIntoView({ behavior: 'smooth' });
            }
        },
        [key, hash]);

    if (songComments === undefined) {
        return (<React.Fragment/>);
    }

    function items(): Array<JSX.Element> {
        if (songComments === undefined) {
            return new Array<JSX.Element>();
        }

        const retVal = new Array<JSX.Element>();

        for(let i = 0; i < songComments.length; i++) {
            const item: SongCommentMedia = songComments[i];

            var key = `GalleryComment|${item.Id}`;

            retVal.push(<GalleryItem key={key} songComment={item}/>);
        }

        return retVal;

    }

    return (<div className="d-flex flex-wrap justify-content-center">
                <ul className="gallery-timeline">{items()}</ul>
            </div>);
}

export default GalleryScreen;