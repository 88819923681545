import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router';
import AxiosSetup from "../api/AxiosSetup";

import ArtistPane from "./Details/ArtistPane";
import HistoryDetailsPane from "./Details/HistoryDetailsPane";
import CommentTimelinePane from "./Details/CommentTimelinePane";

import {
    HistoryClient,
    HistoryItem,
    ContestYear,
    ContestRound,
    ContestNumberOfSongs
} from "../Client";

let axiosSetup = new AxiosSetup();

let client = new HistoryClient(undefined, axiosSetup.Create());

interface IHistoryPaneProps {
    children: HistoryItem | undefined;
    year: ContestYear | undefined;
    round: ContestRound | undefined;
    numberOfSongs : ContestNumberOfSongs | undefined;
}

function HistoryPane(props: IHistoryPaneProps) {
    const navigate = useNavigate();

    if (props.children === undefined || props.children.Song === undefined) {
        return (<React.Fragment/>);
    }

    function next() {
        const rankAsNumber = props.children?.Rank as number;

        const nextUrl = `/History/${props.year}/${props.round}/${rankAsNumber + 1}`;

        navigate(nextUrl, { replace: true});
    }

    function previous() {
        const rankAsNumber = props.children?.Rank as number;

        const nextUrl = `/History/${props.year}/${props.round}/${rankAsNumber - 1}`;

        navigate(nextUrl, { replace: true});
    }

    function eject() {
        const nextUrl = `/History/${props.year}/${props.round}`;

        navigate(nextUrl, { replace: true});
    }


    if (props.children.Song.Comments !== null && props.children.Song.Comments !== undefined && props.children.Song.Comments.length > 0) {
        return (<div className="card-body p-0">
                    <div className="d-flex flex-column">
                <ArtistPane country={props.children.Song.Country} song={props.children.Song} runningOrder={props.children.Rank} year={props.year} includeSongInformation={true}/>
                        <HistoryDetailsPane item={props.children} year={props.year} numberOfSongs={props.numberOfSongs} next={next} previous={previous} eject={eject}/>
                        <CommentTimelinePane item={props.children}/>
                    </div>
                </div>);
    }

    return (<div className="card-body p-0">
                <div className="d-flex flex-column">
                    <ArtistPane country={props.children.Song.Country} song={props.children.Song} runningOrder={props.children.Rank} year={props.year} includeSongInformation={true}/>
                    <HistoryDetailsPane item={props.children} year={props.year} numberOfSongs={props.numberOfSongs} next={next} previous={previous} eject={eject}/>
                </div>
            </div>);
}

function HistoryDetailScreen() {
    const { year, round, rank } = useParams();
    const navigate = useNavigate();

    const[
        item, setItem
    ] = useState<HistoryItem |
                 undefined>();

    const[
        numberOfSongs, setNumberOfSongs
    ] = useState(0);
    
    const [
        fadeIn, setFadeIn
    ] = useState(true);

    useEffect(() => {
            if (year === undefined || rank === undefined || round === undefined) {
                return;
            }

            var yearAsNumber = parseInt(year);
            var rankAsNumber = parseInt(rank);

            const promise = client.result2(yearAsNumber,
                round);

            promise.then(wholeYear => {
                    setNumberOfSongs(wholeYear.length);

                    const innerPromise = client.item2(yearAsNumber,
                        round,
                        rankAsNumber);

                    innerPromise.then(historyItem => {
                            if (historyItem === undefined) {
                                return;
                            }

                            setItem(historyItem);
                        })
                        .catch(async ex => {
                            if (ex.status === 401 || ex.status === 403) {
                                await axiosSetup.Refresh();
                            }

                            navigate("/");
                        });

                })
                .catch(async ex => {
                    if (ex.status === 401 || ex.status === 403) {
                        await axiosSetup.Refresh();
                    }

                    navigate("/");
                });
        },
        [year, round, rank]);

    useEffect(() => {
            setFadeIn(true);
        },
        [
            item
        ]);

    if (item === undefined) {
        return (<React.Fragment/>);
    }

    function toContestYear(): ContestYear | undefined {
        if (year === undefined) {
            return undefined;
        }

        const yearAsNumber = parseInt(year);

        return yearAsNumber as ContestYear;
    }

    function toContestRound(): ContestRound | undefined {
        if (round === undefined) {
            return undefined;
        }

        return round as ContestRound;
    }

    function toContestNumberOfSongs() : ContestNumberOfSongs {
        return numberOfSongs as ContestNumberOfSongs;
    }

    function handleAnimationEnd(event : any) {
        setFadeIn(false);
    }

    const css = fadeIn
        ? "contest-detail text-dark border-0 animate__animated animate__fast animate__fadeIn"
        : "contest-detail text-dark border-0 animate__animated animate__fast";

    return(
        <div className="d-flex me-0 flex-lg-row flex-md-row flex-column me-0 w-100">
            <div className="flex-grow-1 me-2">
                <div className={css} onAnimationEnd={handleAnimationEnd}>
                    <HistoryPane year={toContestYear()} round={toContestRound()} numberOfSongs={toContestNumberOfSongs()}>{item}</HistoryPane>
                </div>
            </div>
        </div>);
}

export default HistoryDetailScreen;