import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import AxiosSetup from "../api/AxiosSetup";
import {
    ScoresheetClient,
    ScoresheetIndex,
    CurrentIndex,
    Country,
    RunningOrder,
    Score,
    ContestYear,
    ResultsAvailable
} from "../Client";

import "../SongScoreboard.min.css";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../node_modules/animate.css/animate.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faRemove, faSort } from "@fortawesome/free-solid-svg-icons";
import FlagLarge from "./Country/FlagLarge";
import FlagMedium from "./Country/FlagMedium";

interface IKeyedScoresheetIndexProps {
    children: ScoresheetIndex;
    currentRunningOrder: RunningOrder | undefined;
    hasTie: boolean;
    key: string;
    year: ContestYear | undefined;
    resultsAvailable: ResultsAvailable | undefined;
}

interface IOverviewAlertProps {
    runningOrder: RunningOrder | undefined;
    currentRunningOrder: RunningOrder | undefined;
    score: number;
    hasTie: boolean;
    resultsAvailable: ResultsAvailable | undefined;
}

let axiosSetup = new AxiosSetup();

let client = new ScoresheetClient(undefined, axiosSetup.Create());

function CurrentRunningOrder(props: IOverviewAlertProps) : JSX.Element {
    if (props.resultsAvailable === undefined || props.resultsAvailable as boolean === true) {
        return <React.Fragment/>;
    }

    if(props.runningOrder === props.currentRunningOrder) {
        return (
            <div className="animate__animated animate__tada animate__repeat-3 animate__delay-1s">
                <span className="position-absolute rounded-pill translate-middle badge bg-success"><FontAwesomeIcon icon={faCheck}/></span>
            </div>);
    }

    return <React.Fragment/>;
}

function Unscored(props: IOverviewAlertProps): JSX.Element {
    if (props.resultsAvailable === undefined || props.resultsAvailable as boolean === true) {
        return <React.Fragment/>;
    }

    if (props.currentRunningOrder !== undefined && props.runningOrder !== undefined && props.runningOrder < props.currentRunningOrder && props.score === 0) {
        return (
            <div className="animate__animated animate__bounce animate__repeat-3 animate__delay-1s">
                <span className="position-absolute translate-middle badge rounded-pill bg-danger"><FontAwesomeIcon icon={faRemove}/></span>
            </div>);
    }

    return <React.Fragment/>;
}

function IsTied(props: IOverviewAlertProps): JSX.Element {
    if (props.resultsAvailable === undefined || props.resultsAvailable as boolean === true) {
        return <React.Fragment/>;
    }

    if (props.runningOrder !== props.currentRunningOrder && props.hasTie && props.score !== 0) {
        return (
            <div className="animate__animated animate__shakeX animate__repeat-3 animate__delay-1s">
                <span className="position-absolute translate-middle rounded-pill badge bg-warning"><FontAwesomeIcon icon={faSort}/></span>
            </div>);
    }

    return <React.Fragment/>;
}

interface IEurovisionScore {
    eurovisionScore: number;
}

function EurovisionScore(props: IEurovisionScore): JSX.Element {
    if (props.eurovisionScore > 0) {
        return (
            <div className="animate__animated animate__rollIn animate__slow">
                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill eurovision-score-bg">{props.eurovisionScore}</span>
            </div>);
    }
    return <React.Fragment/>;
}

function OverviewItem(props: IKeyedScoresheetIndexProps): JSX.Element {
    const navigate = useNavigate();

    function getScore(score: Score |
                             undefined): number {
        if (score === null || score === undefined) {
            return 0;
        }

        return score as number;
    }

    function goToScoresheet() {
        const item = props.children;

        const runningOrder = item.RunningOrder as number;

        navigate(`/Scoresheet/Contest/${runningOrder}`);
    }

    const item = props.children;

    const year = props.year;

    const score = getScore(item.Score);

    return (
        <li className="m-2 ms-lg-3 me-lg-3 mt-lg-3 scoresheet-item animate__animated animate__zoomIn animate__slow">
            <div className="clickable" onClick={goToScoresheet}>
                <div className="scoresheet-background-required">
                    <div className="align-content-center h5 mb-0">
                        <CurrentRunningOrder runningOrder={item.RunningOrder} currentRunningOrder={props.currentRunningOrder} score={score} hasTie={props.hasTie} resultsAvailable={props.resultsAvailable}/>
                        <Unscored runningOrder={item.RunningOrder} currentRunningOrder={props.currentRunningOrder} score={score} hasTie={props.hasTie} resultsAvailable={props.resultsAvailable}/>
                        <IsTied runningOrder={item.RunningOrder} currentRunningOrder={props.currentRunningOrder} score={score} hasTie={props.hasTie} resultsAvailable={props.resultsAvailable}/>
                        <EurovisionScore eurovisionScore={item.EurovisionScore as number}/>
                        <FlagLarge country={item.Country} year={year} css="rounded d-none d-md-block d-lg-block d-xl-block"/>
                        <FlagMedium country={item.Country} year={year} css="rounded d-block d-md-none d-lg-none d-xl-none"/>
                    </div>
                    <div className="row p-1 pb-0">
                        <div className="d-none d-md-flex d-lg-flex d-xl-flex col-md-8 animate__animated animate__slideInRight animate__slow">
                            <span className="text-small text-nowrap">{item?.Country?.Name}</span>
                        </div>
                        <div className="col-12 col-md-4 text-number text-end animate__animated animate__fadeIn animate__slow">
                            <strong><span className="index-score text-number align-top">{item.Score}</span></strong>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
}

interface IOverviewViewProps {
    runningOrder : RunningOrder | undefined;
    year: ContestYear | undefined;
    resultsAvailable: ResultsAvailable | undefined;
}

function OverviewView(props: IOverviewViewProps) : JSX.Element {
    const [
        overview, setOverview
    ] = useState<CurrentIndex |
                 undefined>(undefined);

    const [
        loaded, setLoaded
    ] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
            if (loaded) {
                return;
            }

            const promise = client.overview();

            promise.then(ov => {
                    if (ov !== undefined) {
                        setOverview(ov);

                        setLoaded(true);
                    }
                })
                .catch(async ex => {
                    if (ex.status === 401 || ex.status === 403) {
                        await axiosSetup.Refresh();
                    }

                    navigate("/");
                });
        },
        []);

    function hasTie(item: ScoresheetIndex,
        items: Array<ScoresheetIndex>): boolean {

        if (props.runningOrder === undefined) {
            return false;
        }

        for (let i = 1; i < items.length; i++) {
            const compareTo = items[i];

            if (compareTo.RunningOrder === item.RunningOrder) {
                continue;
            }

            if (compareTo > props.runningOrder) {
                continue;
            }

            if (compareTo.Score === item.Score) {
                return true;
            }
        }

        return false;
    }

    function items(): Array<JSX.Element> {
        if (overview === undefined) {
            return new Array();
        }

        const items = overview.Items;

        if (items === undefined || items === null) {
            return new Array();
        }

        return items.map(item => {
            var key = `running_order|overvieer|${item.RunningOrder}`;

            return (<OverviewItem key={key} currentRunningOrder={props.runningOrder} hasTie={hasTie(item, items)} year={props.year}  resultsAvailable={props.resultsAvailable}>{item}</OverviewItem>);
        });
    }

    if (overview !== undefined) {
        return (
            <ul key="overview" className="scoresheet p-0 pt-lg-2 mt-0 mb-0 d-flex flex-wrap justify-content-center w-100">
                {items()}
            </ul>
        );
    }

    return <React.Fragment/>;
}

export default OverviewView;