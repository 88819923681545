import React from 'react';
import FlagTiny from "../Country/FlagTiny";

import {
    Scoreboard,
    ContestYear
} from "../../Client";

interface ICountryItemProps {
    scoreboard: Scoreboard;
    year: ContestYear | undefined;

    handleClick(item: Scoreboard) : void;
}

const CountryItem: React.FC<ICountryItemProps> = (props) => {

    if (props.scoreboard.Country === undefined) {
        return (<React.Fragment/>);
    }

    return (
        <li className="pb-1 pt-1 pe-1 ps-1 list-group-item clickable clickable-dark" onClick={() => props.handleClick(props.scoreboard) }>
            <div className="row h-100 w-100 align-items-center">
                <div className="col-3 h-100">
                    <div className="row h-100 align-items-center">
                        <div className="col-6 text-number h4 fw-bolder mb-0 float-end text-nowrap">
                            {props.scoreboard.Rank}
                        </div>
                        <div className="col-6 text-end">
                            <FlagTiny country={props.scoreboard.Country} year={props.year} css=""/>
                        </div>
                    </div>
                </div>
                <div className="col-7 h4 mb-0">
                    {props.scoreboard.Country.Name}
                </div>
                <div className="col-2 text-number h4 fw-bolder mb-0">
                    {props.scoreboard.Score}
                </div>
            </div>
        </li>);
}

export default CountryItem;