function InstructionScreen() : JSX.Element
{
    const wifi = `${process.env.REACT_APP_API_URL}api/Home/Wifi`;
    const connection= `${process.env.REACT_APP_API_URL}api/Home/Connection`;


    return(<div className="mx-auto d-block p-4">
        <div className="row">
            <div className="col-6 text-center">
                <div className="text-large text-white">1. Scan this...</div>
            </div>
            <div className="col-6 text-center">
                <div className="text-large text-white">2. Then this...</div>
            </div>
        </div>
        <div className="row">
            <div className="col-6 d-flex justify-content-center">
                <img src={wifi} className="image-fluid rouded"/>
            </div>
            <div className="col-6 d-flex justify-content-center">
                <img src={connection} className="image-fluid rouded"/>
            </div>
        </div>
    </div>);
}

export default InstructionScreen;