import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

import AxiosSetup from "../api/AxiosSetup";

import {
    ScoresheetClient,
    Frame,
    FrameUser,
    FrameScoreboard,
    ContestYear,
    Rank,
    FrameType,
    Country,
    User
} from "../Client";

import ResultLine from "./Presenter/ResultLine";
import CurrentVotes from "./Presenter/CurrentVotes";

let axiosSetup = new AxiosSetup();

let client = new ScoresheetClient(undefined, axiosSetup.Create());

interface ICurrentWithPreviousRank {
    scoreboard: FrameScoreboard;
    prevRank: Rank | undefined;
}

interface IResultPresenterScreenProps {
    year: ContestYear | undefined;
    setPresenterMode(presenterMode: boolean) : void;
}

function ResultPresenterScreen(props: IResultPresenterScreenProps) : JSX.Element {
    const navigate = useNavigate();

    const[
        frameType, setFrameType
    ] = useState<FrameType |
                 undefined>(undefined);

    const[
        frame, setFrame
    ] = useState<Frame |
                 undefined>(undefined);

    const[
        frameId, setFrameId
    ] = useState(0);

    const[
        breakPoint, setBreakPoint
    ] = useState(0);

    const[
        latestVoter, setLatestVoter
    ] = useState<FrameUser |
                 undefined>(undefined);

    const[
        currentVote, setCurrentVote
    ] = useState<Country |
                 undefined>(undefined);

    const[
        scoreboard, setScoreboard
    ] = useState<Array<ICurrentWithPreviousRank>>(new Array<ICurrentWithPreviousRank>());

    const[presentScores, setPresentScores] = useState(false);

    useEffect(() => {
        props.setPresenterMode(true);

        if (props.year === undefined) {
            return;
        }

        if (frameId !== 0) {
            return;
        }

        if (presentScores === false) {
            return;
        }

        setTimeout(() => updateFrame(), 2500);
    }, [props, presentScores]);


    useEffect(() => {
        if (props.year === undefined) {
            return;
        }

        if (presentScores === false) {
            return;
        }

        updateFrame();
    }, [frameId]);

    function updateFrame() {
        const promise = client.frame2(frameId);

        promise.then(f => {
            if (f !== undefined && f.Scoreboard !== null && f.Scoreboard !== undefined && f.FrameType !== undefined) {
                setBreakPoint(f.Scoreboard.length % 2 === 0 ? (f.Scoreboard.length / 2) : ((f.Scoreboard.length + 1) / 2));
                
                const newScoreboard = new Array<ICurrentWithPreviousRank>();

                for (let i = 0; i < f.Scoreboard.length; i++) {
                    const item = f.Scoreboard[i];

                    const prev = prevRank(frame,
                        item);

                    newScoreboard.push({
                        scoreboard: item,
                        prevRank: prev
                    });
                }

                setScoreboard(newScoreboard);

                setFrame(f);
                setFrameType(f.FrameType);

                if (f.CurrentVotes !== undefined && f.CurrentVotes !== null && f.CurrentVotes.length > 0) {
                    setCurrentVote(f.CurrentVotes[f.CurrentVotes.length - 1].Country);
                }

                if (f.Users !== undefined && f.Users !== null && f.Users.length > 0) {
                    var user = f.Users[f.Users.length - 1];
                    setLatestVoter(user);
                }
                else {
                    setLatestVoter(undefined);
                }


                if (f.FrameType !== FrameType._4) {
                    setTimeout(() => setFrameId(frameId + 1),
                        (f.DisplayInSeconds as number) * 1000);
                }
                else {
                    setTimeout(() => {
                            const resultsPromise = client.release();

                            resultsPromise.then(() => {
                                    console.log("Released Results");
                                })
                                .catch(ex => {
                                    console.log(`Error Releasing Results => ${ex}`);
                                });
                        },
                        10000);
                }
            }
        }).catch(async ex  => {
            if (ex.status === 401 || ex.status === 403) {
                await axiosSetup.Refresh();
            }

            navigate("/");
        });
    }

    function prevRank(previousFrame: Frame | undefined, frameScoreboard: FrameScoreboard): Rank |
                         undefined {
        if (frame === undefined || previousFrame === undefined) {
            return undefined;
        }

        if (frameScoreboard.Country === undefined) {
            return undefined;
        }

        if (previousFrame.Scoreboard === undefined || previousFrame.Scoreboard === null) {
            return undefined;
        }

        const countryId = frameScoreboard.Country.Id;

        for(let i = 0; i < previousFrame.Scoreboard.length; i++) {
            const item = previousFrame.Scoreboard[i];

            if (item.Country !== undefined && item.Country.Id === countryId) {
                return item.Rank;
            }
        }

        return undefined;
    }

    function items(): Array<JSX.Element> {
        if (scoreboard === undefined || scoreboard === undefined || scoreboard.length === 0 || frame === undefined) {
            return new Array<JSX.Element>();
        }

        return scoreboard.map(item => {
            var key = Math.random()
                .toString();

            return (<ResultLine key={key} frame={item.scoreboard} prevRank={item.prevRank} year={props.year} type={frame.FrameType} currentVote={currentVote} suffix={0}/>);
        });
    }

    function firstHalf(): Array<JSX.Element> {
        if (scoreboard === undefined || scoreboard === undefined || scoreboard.length === 0 || frame === undefined) {
            return new Array<JSX.Element>();
        }

        const retVal = new Array<JSX.Element>();

        for (let i = 0; i < breakPoint; i++) {
            const item = scoreboard[i];
            const key = Math.random()
                .toString();

            retVal.push(<ResultLine key={key} frame={item.scoreboard} prevRank={item.prevRank} year={props.year} type={frame.FrameType} currentVote={currentVote} suffix={1}/>);
        }

        return retVal;
    }

    function secondHalf(): Array<JSX.Element> {
        if (scoreboard === undefined || scoreboard === undefined || scoreboard.length === 0 || frame === undefined) {
            return new Array<JSX.Element>();
        }

        const retVal = new Array<JSX.Element>();

        for (let i = breakPoint; i < scoreboard.length; i++) {
            const item = scoreboard[i];
            const key = Math.random()
                .toString();

            retVal.push(<ResultLine key={key} frame={item.scoreboard} prevRank={item.prevRank} year={props.year} type={frameType} currentVote={currentVote} suffix={2}/>);
        }

        return retVal;
    }

    function toUser(): User | undefined {
        if (latestVoter === undefined) {
            return undefined;
        }

        return {
            Id : latestVoter.UserId,
            Name : latestVoter.DisplayName
        };
    }

    if (!presentScores) {
        return (<div className="w-100 vh-100">
                    <div className="d-flex align-items-center justify-content-center h-100">
                        <div className="btn btn-lg btn-success" onClick={() => setPresentScores(true)}>
                            Start BeaverStream!
                        </div>
                    </div>
                </div>);
    }

    if (frame === undefined) {
        return (<React.Fragment/>);
    }

    return (
        <div className="m-1 h1 p-1">
            <div className="row d-none d-xl-flex d-lg-flex mt-2 me-0">
                <div className="col-6">
                    <div className="card border-light">
                        <ul className="list-group list-group-flush text-dark">
                            {firstHalf()}
                        </ul>
                    </div>
                </div>
                <div className="col-6 ">
                    <div className="card border-light glass">
                        <ul className="list-group list-group-flush text-dark">
                            {secondHalf()}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="row d-flex d-lg-none d-xl-none mt-0 me-0">
                <div className="col-12">
                    <div className="card border-light">
                        <ul className="list-group list-group-flush text-dark">
                            {items()}
                        </ul>
                    </div>
                </div>
            </div>
            <CurrentVotes year={props.year} frame={frame} user={toUser()}/>
        </div>);
}

export default ResultPresenterScreen;