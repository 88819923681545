import React, { useEffect, useState } from 'react';

import {
    FrameScoreboard,
    ContestYear,
    Rank,
    FrameType,
    Country
} from "../../Client";

import FlagMedium from "../Country/FlagMedium";

import ScoreCount from "./ScoreCount";

interface IResultLineProps {
    frame: FrameScoreboard;
    type: FrameType | undefined;
    prevRank: Rank | undefined;
    year: ContestYear | undefined;
    currentVote: Country | undefined;
    suffix: number;
}

function ResultLine(props: IResultLineProps): JSX.Element {

    const[
        css, setCss
    ] = useState("row h-100 w-100 align-items-center m-0");

    const[
        scored, setScored
    ] = useState<boolean>(false);

    const[
        currentScoring, setCurrentScoring
    ] = useState<boolean>(false);

    const[
        slideUp, setSlideUp
    ] = useState<boolean>(false);

    const[
        slideDown, setSlideDown
    ] = useState<boolean>(false);

    const[
        heartbeat, setHeartbeat
    ] = useState<boolean>(false);

    const[
        winner, setWinner
    ] = useState<boolean>(false);

    const id = Math.random()
        .toString();

    let rank = "";
    let score = "";

    useEffect(() => {
            if (props.frame.Country === undefined || props.currentVote === undefined || props.prevRank === undefined) {
                return;
            }

            var country = props.frame.Country;

            var currentRank = props.frame.Rank as number;
            var prevRank = props.prevRank as number;
            var hasScored = props.frame.Scored as boolean;

            if (props.type === FrameType._4 && currentRank === 1) {
                setWinner(true);
            }

            if (props.type === FrameType._2) {
                if (country.Id === props.currentVote.Id) {
                    setScored(false);
                    setCurrentScoring(true);

                    if (currentRank === prevRank) {
                        setHeartbeat(true);
                        setSlideUp(false);
                        setSlideDown(false);

                        return;
                    }
                }
                else if (hasScored === true) {
                    setScored(true);
                    setCurrentScoring(false);
                }
            }
            else if(props.type === FrameType._3) {
                setScored(false);
                setCurrentScoring(false);
            }

            setHeartbeat(false);

            if (props.type === FrameType._2) {
                if (currentRank < prevRank) {
                    setSlideUp(true);
                    setSlideDown(false);

                    return;
                }

                if (currentRank > prevRank) {
                    setSlideUp(false);
                    setSlideDown(true);
                }
            }
        },
        [
            props
        ]);

    useEffect(() => {
            updateCss();
        },
        [
            heartbeat, slideUp, slideDown, currentScoring, scored, winner
        ]);

    function updateCss() {
        let baseCss = "row h-100 w-100 align-items-center m-0";

        if (heartbeat) {
            baseCss += " animate__animated animate__heartBeat";
        }
        else if (slideUp) {
            baseCss += " animate__animated animate__fadeInUp";
        }
        else if (slideDown) {
            baseCss += " animate__animated animate__fadeInDown";
        }

        if (currentScoring) {
            baseCss += " bg-primary text-white bg-gradient";
        } else if (scored) {
            baseCss += " bg-info bg-gradient";
        }

        if (winner) {
            baseCss += " bg-success text-white bg-gradient";
        }

        setCss(baseCss);
    }

    function handleAnimationEnd(event : any) {
        setHeartbeat(false);
        setSlideUp(false);
        setSlideDown(false);
    }

    if (props.frame.Country === undefined || props.frame.Rank === undefined || props.frame.Points === undefined || props.type === undefined) {
        return (<React.Fragment/>);
    }

    if (props.type === FrameType._1) {
        return(
            <li className="p-0 list-group-item m-0">
                <div className="row h-100 w-100 align-items-center m-0 animate__animated animate__flipInX">
                    <div className="col-5 h-100 mt-3 mb-3">
                        <div className="row h-100 align-items-center">
                            <div className="col-2 text-number h0 text-nowrap fw-bolder mb-0 float-end">
                                &nbsp;
                            </div>
                            <div className="col-2 text-end">
                                <FlagMedium year={props.year} country={props.frame.Country} css={undefined}/>
                            </div>
                            <div className="col-8 h0 text-nowrap fw-bolder">
                                {props.frame.Country.Name}
                            </div>
                        </div>
                    </div>
                    <div className="col-5 h0 text-end mb-0">
                        &nbsp;
                    </div>
                    <div className="col-2 text-number h1 fw-bolder mb-0">
                        &nbsp;
                    </div>
                </div>
            </li>);
    }

    if (props.frame.Points as number > 0 || props.type === FrameType._4) {
        rank = props.frame.Rank.toString();
        score = props.frame.Points.toString();
    }

    var key = "SCORECOUNT." + id;

    return(
        <li className="p-0 list-group-item m-0">
            <div className={css} onAnimationEnd={handleAnimationEnd} id={id}>
                <div className="col-5 h-100 mt-3 mb-3">
                    <div className="row h-100 align-items-center">
                        <div className="col-2 text-number h0 text-nowrap fw-bolder mb-0 float-end">
                            <span>{rank}</span>
                        </div>
                        <div className="col-2 text-end">
                            <FlagMedium year={props.year} country={props.frame.Country} css={undefined}/>
                        </div>
                        <div className="col-8 h0 text-nowrap fw-bolder">
                            {props.frame.Country.Name}
                        </div>
                    </div>
                </div>
                <div className="col-5 text-large text-end mb-0 text-nowrap">
                    <ScoreCount frame={props.frame} key={key}/>
                </div>
                <div className="col-2 text-number h0 fw-bolder mb-0">
                    {score}
                </div>
            </div>
        </li>);
}

export default ResultLine;