import React from 'react';
import {
    HistoryItem,
    Comment,
    User
} from "../../Client";

interface ICommentTimelinePaneProps {
    item: HistoryItem;
}

interface ICommentProps {
    item: Comment
}

interface IUserImageProps {
    user: User;
}

function UserImage(props: IUserImageProps) {

    const baseUrl = `${process.env.REACT_APP_API_URL}api/User/${props.user.Id}/Thumb/`;

    const image = baseUrl + "1";

    const sourceSet = baseUrl + "1 1x, " + baseUrl + "1.5 1.5x, " + baseUrl + "2 2x, " + baseUrl + "3 3x, " + baseUrl + "4 4x";

    return (
        <img className="rounded"
            srcSet={sourceSet}
            src={image} alt={props.user.Name?.toString()} height={40} width={40}/>
    );
}

function CommentLine(props: ICommentProps): JSX.Element {
    if (props.item.User === undefined) {
        return(<React.Fragment/>);
    }
    
    return(
        <li className="list-group-item animate__animated animate__fadeInDown">
            <div className="d-flex align-items-center w-100">
                <UserImage user={props.item.User}/>
                <div className="d-flex flex-column align-items-start p-1">
                    <div className="d-flex align-items-center text-white">
                        <span>
                            {props.item.User.Name}
                        </span>
                    </div>
                </div>
            </div>
            <div className="w-100 text-white">
                <span>
                    {props.item.Text}
                </span>
            </div>
        </li>
    );
}

function CommentTimelinePane(props: ICommentTimelinePaneProps): JSX.Element {

    function items(): Array<JSX.Element> {
        if (props.item.Song === undefined || props.item.Song.Comments === null || props.item.Song.Comments === undefined) {
            return new Array<JSX.Element>();
        }

        return props.item.Song.Comments.map(item => {
            var key = `COMMENT|${item.Id?.toString()}`;

            return (<CommentLine key={key} item={item}/>);
        });
    }

    return (
        <div className="d-flex flex-column card glass text-small timeline text-white p-2 m-2">
            <ul className="list-group w-100">
                {items()}
            </ul>
        </div>
    );
}

export default CommentTimelinePane;