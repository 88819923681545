import React from 'react';

import {
    HistoryItem,
    ScorePad,
    ScorePadItem
} from "../../Client";

interface IScoredHistoryTabProps {
    item: HistoryItem;
}

interface IScoredCriteria {
    item: ScorePadItem
}

interface IScorePad {
    item: ScorePad
}

function ScorePadHeader(props: IScoredCriteria) {
    const description = props.item?.Criteria?.Description?.toString();

    return(
        <th>
            <div className="text-small rotated-header-content">
                {description}
            </div>
        </th>);
}

function ScorePadScore(props: IScoredCriteria) {
    return (
        <td className="text-number text-small text-nowrap text-end w-10">
            {props.item.Value}
        </td>
    );
}

function Scorepad(props: IScorePad) {
    function items() : Array<JSX.Element> {
        if (props.item.Scores === undefined || props.item.Scores === null) {
            return new Array<JSX.Element>();
        }

        return props.item.Scores.map(item => {
            var key = `SPI|${props.item.User?.Id}|${item.Criteria?.Id}`;

            return (<ScorePadScore key={key} item={item}/>);
        });
    }
    
    if (props.item.User === undefined || props.item.Score === undefined || props.item.EurovisionScore === undefined) {
        return (<React.Fragment/>);
    }

    return (
        <tr>
            <td>
                <span className="text-small fw-bold text-nowrap">
                    {props.item.User.Name}
                </span>
            </td>
            {items()}
            <td className="text-small text-number text-nowrap text-end">
                {props.item.Score}
            </td>
            <td className="text-small text-number fw-bold text-nowrap text-end">
                {props.item.EurovisionScore}
            </td>
        </tr>
    );
}


function ScoredHistoryTab(props: IScoredHistoryTabProps): JSX.Element {
    
    function header(): Array<JSX.Element> {
        const retVal = new Array<JSX.Element>();
        
        if (props.item.ScorePad === undefined || props.item.ScorePad === null || props.item.ScorePad.length === 0) {
            return retVal;
        }

        const work = props.item.ScorePad[0];

        if (work.Scores === undefined || work.Scores === null) {
            return retVal;
        }

        for (let i = 0; i < work.Scores.length; i++) {
            const item = work.Scores[i];
            const key = `SPH|${item.Criteria?.Id}`;

            retVal.push(<ScorePadHeader item={item} key={key}/>);
        }

        return retVal;
    }

    function items(): Array<JSX.Element> {
        if (props.item.ScorePad === undefined || props.item.ScorePad === null || props.item.ScorePad.length === 0) {
            return new Array<JSX.Element>();
        }

        return props.item.ScorePad.map(item => {
            var key = `SP|${item.User?.Id}`;

            return(<Scorepad key={key} item={item}/>);
        });
    }

    if (props.item.ScorePad === undefined || props.item.ScorePad === null || props.item.ScorePad[0].Scores === undefined || props.item.ScorePad[0].Scores === null) {
        return (<React.Fragment/>);
    }

    const colSpan = props.item.ScorePad[0].Scores.length + 3;

    return (                                    
        <div className="active fade show h-100">
            <div className="d-flex flex-column align-items-start h-100 w-100">
                <table className="rotated-header">
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            {header()}
                            <th>
                                <div className="rotated-header-container">
                                    <div className="text-small rotated-header-content">
                                        Total
                                    </div>
                                </div>
                            </th>
                            <th>
                                <div className="rotated-header-container">
                                    <div className="text-small rotated-header-content">
                                        Score
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {items()}
                        <tr>
                            <td colSpan={colSpan} className="text-number">
                                <span className="text-nowrap text-total fw-bolder text-end">
                                    {props.item.Score}
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>);
}

export default ScoredHistoryTab;