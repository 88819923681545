import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AxiosSetup from "../../api/AxiosSetup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlock } from "@fortawesome/free-solid-svg-icons";

import UserImage from "../User/UserImage";

import {
    AdminClient,
    ApplicationUser,
    User
} from "../../Client";

let axiosSetup = new AxiosSetup();

let client = new AdminClient(undefined, axiosSetup.Create());

interface IUserLineProps {
    item: ApplicationUser;
}

function UserLine(props: IUserLineProps) {
    const navigate = useNavigate();

    function handleClick() {

        const url = `/Admin/Draw/${props.item.UserId}`;

        navigate(url);
    }

    function handleReset() {
        client.reset(props.item.UserId?.toString());
    }

    function toUser(): User {
        return {
            Id : props.item.UserId,
            Name : props.item.UserName
        };
    }

    return (
        <div className="row m-1">
            <div className="col-2 clickable" onClick={() => handleClick()}>
                    <UserImage user={toUser()} size={40}/>
            </div>
            <div className="col-4">
                {props.item.DisplayName}
            </div>
            <div className="col-4">
                {props.item.UserName}
            </div>
            <div className="col-2 text-end">
                <div className="btn btn-danger" onClick={() => handleReset()}>Reset Password</div>
            </div>
        </div>);

}

function AdminUserScreen() {
    const navigate = useNavigate();

    const [
        users, setUsers
    ] = useState<ApplicationUser[]>(new Array<ApplicationUser>());

    useEffect(() => {
            var promise = client.users();

            promise.then(allUsers => {
                    setUsers(allUsers);
                })
                .catch(async ex => {
                    if (ex.status === 401 || ex.status === 403) {
                        await axiosSetup.Refresh();
                    }

                    navigate("/");
                });

        },
        []);

    function items(): Array<JSX.Element> {
        const retVal = new Array<JSX.Element>();

        if (users.length === 0) {
            return retVal;
        }

        for (let i = 0; i < users.length; i++) {
            const item = users[i];

            const key = `UL|${item.UserId}`;

            retVal.push(<UserLine key={key} item={item}/>);
        }

        return retVal;
    }

    return(
        <div className="row m-1">
            <div className="col-12">
                <div className="card border-light text-dark">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-6">Name</div>
                            <div className="col-4">UserName</div>
                            <div className="col-2 text-end"><FontAwesomeIcon icon={faUnlock}/></div>
                        </div>
                    </div>
                    <div className="card-body">
                        {items()}
                    </div>
                </div>
            </div>
        </div>);
}

export default AdminUserScreen;