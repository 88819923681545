import React, { useState } from 'react';
import { Button, Tab, Tabs } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignIn, faBackward, faAmbulance } from "@fortawesome/free-solid-svg-icons";
import { faTwitter, faGoogle, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import AxiosSetup from "../api/AxiosSetup";
import {
    IdentityClient
} from "../Client";


let axiosSetup = new AxiosSetup();

let client = new IdentityClient(undefined,
    axiosSetup.Create());

interface ILoginDirectViewProps
{
    setActiveKey(activeKey: string) : void;
}


function LoginDirectView(props: ILoginDirectViewProps): JSX.Element {
    function handleSubmit(event: any) {
        event.preventDefault();

        const target = event.target;

        const username = target.username.value;
        const password = target.password.value;

        const loginRequest = {
            Email: username,
            Password: password
        };

        const promise = client.login(loginRequest);

        promise.then(jwt => {
                localStorage.setItem("auth",
                    JSON.stringify(jwt));

                window.location.reload();
            })
            .catch(ex => {
                window.location.reload();
            });
    }

    return (
        <Form onSubmit={handleSubmit} className="w-100">
            <Form.Group>
                <div className="p-2 w-100">
                    <Form.Control type="text" size="lg" placeholder="User Name" id="username" className="col-12"/>
                </div>
                <div className="p-2 w-100">
                    <Form.Control type="password" size="lg" placeholder="Password" id="password"/>
                </div>
                <div className="d-flex align-items-center">
                    <div className="p-2">
                        <Button variant="primary" className="btn-lg" type="submit">
                            Login <FontAwesomeIcon icon={faSignIn}/>
                        </Button>
                    </div>
                    <div className="p-2">
                        <Button variant="primary" className="btn-lg col-12" onClick={() => props.setActiveKey("oauth")}>
                            Back <FontAwesomeIcon icon={faBackward}/>
                        </Button>
                    </div>
                </div>
            </Form.Group>
        </Form>
    );
}

function LoginView(): JSX.Element {
    const [
        activeKey, setActiveKey
    ] = useState("oauth");

    function twitterClick() {
        const url = process.env.REACT_APP_API_URL + "api/OAuth/Begin";

        window.location.href = url;
    }

    function googleClick() {
        const url = process.env.REACT_APP_API_URL + "api/google/begin";

        window.location.href = url;
    }

    function microsoftClick() {
        const url = process.env.REACT_APP_API_URL + "api/microsoft/begin";

        window.location.href = url;
    }


    return (<Tab.Container id="login-tabs" activeKey={activeKey} onSelect={eventKey => setActiveKey(eventKey ?? "oauth")}>
                <div className="row h-100 login-panel">
                    <div className="col-12 my-auto">
                        <div className="glass card border-dark col-lg-4 col-8 offset-lg-4 offset-2 p-0">
                            <div className="card-header text-white">
                                <div className="card-title h3">Login</div>
                            </div>
                            <div className="card-body">
                                <Tab.Content>
                                    <Tab.Pane eventKey="oauth" className="border-0">
                                        <div className="d-flex flex-column align-items-start">
                                            <div className="p-2 w-100">
                                                <Button variant="primary" className="btn-lg col-12" onClick={() => twitterClick()}>
                                                    <FontAwesomeIcon icon={faTwitter}/>&nbsp;Connect via Twitter
                                                </Button>
                                            </div>
                                            <div className="p-2 w-100">
                                                <Button variant="primary" className="btn-lg col-12" onClick={() => googleClick()}>
                                                    <FontAwesomeIcon icon={faGoogle}/>&nbsp;Connect via Google
                                                </Button>
                                            </div>
                                            <div className="p-2 w-100">
                                                <Button variant="primary" className="btn-lg col-12" onClick={() => microsoftClick()}>
                                                    <FontAwesomeIcon icon={faMicrosoft}/>&nbsp;Connect via Microsoft
                                                </Button>
                                            </div>
                                            <div className="p-2 w-100">
                                                <Button variant="primary" className="btn-lg col-12" onClick={() => setActiveKey("direct")}>
                                                    <FontAwesomeIcon icon={faAmbulance}/>&nbsp;Direct
                                                </Button>
                                            </div>
                                        </div>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="direct" className="border-0">
                                        <div className="d-flex flex-column align-items-start w-100">
                                            <LoginDirectView setActiveKey={setActiveKey}/>
                                        </div>
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </div>
                    </div>
                </div>
            </Tab.Container>);
}

export default LoginView;