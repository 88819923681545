import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AxiosSetup from "../../api/AxiosSetup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faCheck, faRemove } from "@fortawesome/free-solid-svg-icons";

import {
    AdminClient,
    UserMonitor,
    UserMonitorCount,
    ContestNumberOfSongs
} from "../../Client";

let axiosSetup = new AxiosSetup();

let client = new AdminClient(undefined, axiosSetup.Create());

interface IUserMonitorLineProps {
    item: UserMonitor;
    numberOfSongs: ContestNumberOfSongs;
}

interface IUserMonitorStatusProps {
    item: UserMonitorCount;
    numberOfSongs: ContestNumberOfSongs;
}

function UserMonitorStatus(props: IUserMonitorStatusProps) {
    const totalScored = props.item as number;

    if (totalScored === props.numberOfSongs as number)
    {
        return (<FontAwesomeIcon icon={faStar} className="color-green"/>);
    }

    if (totalScored > 10) {
        return (<FontAwesomeIcon icon={faCheck} className="color-yellow"/>);
    }

    return (<FontAwesomeIcon icon={faRemove} className="color-red"/>);
}

function UserMonitorLine(props: IUserMonitorLineProps) {
    if (props.item.Count === undefined) {
        return(<React.Fragment/>);
    }

    return(
        <div className="row">
            <div className="col-8">{props.item.DisplayName}</div>
            <div className="col-2 text-end">{props.item.Count}</div>
            <div className="col-2 text-center">
                <UserMonitorStatus item={props.item.Count} numberOfSongs={props.numberOfSongs}/>
            </div>
        </div>
    );
}

interface IAdminWatchScreenProps {
    numberOfSongs: ContestNumberOfSongs | undefined;
}

function AdminWatchScreen(props: IAdminWatchScreenProps) : JSX.Element {
    const navigate = useNavigate();

    const [
        userMonitors, setUserMonitors
    ] = useState<UserMonitor[]>(new Array<UserMonitor>());

    const[
        lastUpdated, setLastUpdated
    ] = useState("");

    function padTo2Digits(num : number) {
        return num.toString().padStart(2, "0");
    }

    function formatDate(date: Date) {
        return (
            [
                    date.getFullYear(),
                    padTo2Digits(date.getMonth() + 1),
                    padTo2Digits(date.getDate()),
                ].join("-") +
                " " +
                [
                    padTo2Digits(date.getHours()),
                    padTo2Digits(date.getMinutes()),
                    padTo2Digits(date.getSeconds()),
                ].join(":")
        );
    }

    function getMonitor() {
        const promise = client.monitor();

        promise.then(monitor => {
                setLastUpdated(formatDate(new Date()));

                setUserMonitors(monitor);
            })
            .catch(async ex => {
                if (ex.status === 401 || ex.status === 403) {
                    await axiosSetup.Refresh();
                }

                navigate("/");
            });
    }

    useEffect(() => {
            getMonitor();

            const interval = setInterval(() => {
                    getMonitor();
                },
                10000);

            return() => clearInterval(interval);
        },
        []);

    useEffect(() => {
        const promise = client.monitor();

        promise.then(monitor => {
                setLastUpdated(formatDate(new Date()));

                setUserMonitors(monitor);
            })
            .catch(async ex => {
                if (ex.status === 401 || ex.status === 403) {
                    await axiosSetup.Refresh();
                }

                navigate("/");
            });
    }, [props]);

    function items() : Array<JSX.Element> {
        const retVal = new Array<JSX.Element>();
        
        if (props.numberOfSongs === undefined || userMonitors.length === 0) {
            return retVal;
        }

        for (let i = 0; i < userMonitors.length; i++) {
            const item = userMonitors[i];

            const key = `UM|${item.DisplayName}`;

            retVal.push(<UserMonitorLine key={key} item={item} numberOfSongs={props.numberOfSongs}/>);
                
        }

        return retVal;
    }

    if (userMonitors.length === 0) {
        return(<React.Fragment/>);
    }

    
    return (
        <div className="col-12 m-1">
            <div className="card">
                <div className="card-body h1 text-black">
                    <div className="row">
                        <div className="col-12">{lastUpdated}</div>
                    </div>
                    {items()}
                </div>
            </div>
        </div>);
}

export default AdminWatchScreen;