import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";

import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faGlassMartini, faBook, faSignIn, faTrophy, faCogs, faHeart, faListOl, faHistory, faSignOut, faAlignJustify, faCheckCircle, faPaintBrush, faScrewdriver, faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";

import {
    ContestYear,
    ResultsAvailable
} from "../Client";

interface INavigationBarProps {
    isAdmin: boolean;
    authenticated: boolean;
    year: ContestYear | undefined;
    resultsAvailable: ResultsAvailable | undefined;
    presenterMode: boolean;
}

function Logo(): JSX.Element {

    const baseUrl = `${process.env.REACT_APP_API_URL}api/Home/Logo/`;

    const image = baseUrl + "1";

    const sourceSet = baseUrl + "1 1x, " + baseUrl + "1.5 1.5x, " + baseUrl + "2 2x, " + baseUrl + "3 3x, " + baseUrl + "4 4x";

    return (
        <img src={image} srcSet={sourceSet} alt="Beaverlac Cottage" height="30" width="117"/>);

}

function NavigationAdministrationMenu(props: INavigationBarProps): JSX.Element {
    if (props.isAdmin) {
        return (
            <NavDropdown title="Administration" id="nav-dropdown" className="text-nowrap">
                <NavDropdown.Item href="/Admin/Users">Users <FontAwesomeIcon icon={faUser}/></NavDropdown.Item>
                <NavDropdown.Item href="/Admin/Monitor">Drunk Watch <FontAwesomeIcon icon={faGlassMartini}/></NavDropdown.Item>
                <NavDropdown.Item href="/Admin/Result">Result <FontAwesomeIcon icon={faAlignJustify}/></NavDropdown.Item>
                <NavDropdown.Item href="/Admin/InContestPresenter">In-Contest <FontAwesomeIcon icon={faScrewdriver}/></NavDropdown.Item>
                <NavDropdown.Item href="/Admin/Instructions">Instructions <FontAwesomeIcon icon={faInfoCircle}/></NavDropdown.Item>
            </NavDropdown>);

    }

    return <React.Fragment/>;
}

function MoreMenu(props: INavigationBarProps): JSX.Element {
    return (
        <NavDropdown title="More..." id="nav-dropdown" className="text-nowrap" align="end">
            <NavDropdown.Item href="/User/Settings">Settings <FontAwesomeIcon icon={faCogs}/></NavDropdown.Item>
            <NavDropdown.Item href="/Gallery">Gallery <FontAwesomeIcon icon={faPaintBrush}/></NavDropdown.Item>
            <NavDropdown.Item href="/Home/Privacy">Privacy Policy <FontAwesomeIcon icon={faCheckCircle}/></NavDropdown.Item>
            <NavDropdown.Item href="/Home/Logout">Logout <FontAwesomeIcon icon={faSignOut}/></NavDropdown.Item>
        </NavDropdown>);
}

function NavigationBar(props: INavigationBarProps): JSX.Element {
    const [
        authenticated, setAuthenticated
    ] = useState(props.authenticated);

    const [
        activeKey, setActiveKey
    ] = useState("/");

    const [presenterMode, setPresenterMode] = useState(false);

    var location = useLocation();

    useEffect(() => {
            setAuthenticated(props.authenticated);

            findActiveKey();

            setPresenterMode(props.presenterMode);
        },
        [
            props
        ]);

    function findActiveKey() {
        const path = location.pathname;

        if (!(set(path,
                "/Result") ||
            set(path,
                "/History") ||
            set(path,
                "/Achievement") ||
            set(path,
                "/User/Settings") ||
            set(path, 
                "/Admin"))) {
            setActiveKey("/");
        }
    }

    function set(path: string, compareTo: string): boolean {
        if (path.startsWith(compareTo)) {
            setActiveKey(compareTo);
            return true;
        }

        return false;
    }

    if (presenterMode) {
        return (<React.Fragment/>);
    }

    if (!authenticated) {
        return (
            <Navbar bg="dark" variant="dark" expand="lg" fixed="top" collapseOnSelect className="pt-1 pb-1">
                <Container fluid>
                    <Navbar.Brand href="/">
                        <Logo/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="unauthenticated-navbar">
                        <Nav className="ms-auto" activeKey="/Login">
                            <Nav.Link href="/Home/Privacy" className="text-nowrap">Privacy Policy <FontAwesomeIcon icon={faCheckCircle}/></Nav.Link>
                            <Nav.Link href="/" className="text-nowrap">Login <FontAwesomeIcon icon={faSignIn}/></Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }
    
    if (props.resultsAvailable !== undefined && props.resultsAvailable) {
        return (
            <Navbar bg="dark" variant="dark" expand="lg" fixed="top" collapseOnSelect className="pt-1 pb-1">
                <Container fluid>
                    <Navbar.Brand href="/">
                        <Logo/>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="authenticated-navbar-with-result">
                        <Nav className="ms-auto" activeKey={activeKey} onSelect={eventKey => setActiveKey(eventKey ?? "/")}>
                            <NavigationAdministrationMenu isAdmin={props.isAdmin} authenticated={props.authenticated} year={props.year} resultsAvailable={props.resultsAvailable} presenterMode={presenterMode}/>
                            <Nav.Link href="/" className="text-nowrap">{props.year} <FontAwesomeIcon icon={faHeart}/></Nav.Link>
                            <Nav.Link href="/Result" className="text-nowrap">Result <FontAwesomeIcon icon={faListOl}/></Nav.Link>
                            <Nav.Link href="/History" className="text-nowrap">History <FontAwesomeIcon icon={faHistory}/></Nav.Link>
                            <MoreMenu isAdmin={props.isAdmin} authenticated={props.authenticated} year={props.year} resultsAvailable={props.resultsAvailable} presenterMode={presenterMode}/>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        );
    }

    return (
        <Navbar bg="dark" variant="dark" expand="lg" fixed="top" collapseOnSelect className="pt-1 pb-1">
            <Container fluid>
                <Navbar.Brand href="/">
                    <Logo/>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="authenticated-navbar">
                    <Nav className="ms-auto" activeKey={activeKey} onSelect={eventKey => setActiveKey(eventKey ?? "/")}>
                        <NavigationAdministrationMenu isAdmin={props.isAdmin} authenticated={props.authenticated} year={props.year} resultsAvailable={props.resultsAvailable} presenterMode={presenterMode}/>
                        <Nav.Link href="/" className="text-nowrap">{props.year} <FontAwesomeIcon icon={faHeart}/></Nav.Link>
                        <Nav.Link href="/History" className="text-nowrap">History <FontAwesomeIcon icon={faHistory}/></Nav.Link>
                        <MoreMenu isAdmin={props.isAdmin} authenticated={props.authenticated} year={props.year} resultsAvailable={props.resultsAvailable} presenterMode={presenterMode}/>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );

}

export default NavigationBar;