import React, { useEffect, useState } from 'react';
import {
    Song,
    RunningOrder,
    Lyric,
    ScoredCriteria,
    VotingOpen,
    ContestNumberOfSongs,
    HashTag,
    User,
    ScoresheetItem,
    ContestYear
} from "../../Client";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMusic, faBullseye, faFile, faBackward, faForward, faEject, faSort } from "@fortawesome/free-solid-svg-icons";
import { Button, Nav, Tab } from 'react-bootstrap';
import ScoreTab from './ScoreTab';
import AboutTab from './AboutTab';
import LyricsTab from './LyricsTab';
import TiedWithTab from './TiedWithTab';

interface ILyricProps {
    children: Lyric[] | null | undefined;
    ties: ScoresheetItem[];
    keyPrefix: string;
}

interface IDetailPaneProps {
    runningOrder: RunningOrder | undefined;
    numberOfSongs: ContestNumberOfSongs | undefined;
    song: Song | undefined;
    scores: ScoredCriteria[] | null | undefined;
    votingOpen : VotingOpen | undefined;
    hashTags: HashTag[] | null | undefined;
    drawnBy?: User;
    ties: ScoresheetItem[];
    year: ContestYear | undefined;

    next(event : any) : void;
    previous(event : any) : void;
    eject(event : any) : void;
    navigateTo(runningOrder: RunningOrder) : void;

    updateScores(song: Song | undefined, scoredCriteria: ScoredCriteria[]) : void;
}
function DetailsPane(props: IDetailPaneProps) {
    const [
        activeKey, setActiveKey
    ] = useState("score");


    useEffect(() => {
            setActiveKey("score");
        },
        [
            props
        ]);

    function getLyrics(song: Song | undefined) : Lyric[]
    {
        if (song === undefined) {
            return new Array<Lyric>();
        }

        if (song.Lyrics === undefined || song.Lyrics === null) {
            return new Array<Lyric>();
        }

        return song.Lyrics;
    }

    function getTitle(lyric : Lyric) : string
    {
        if (lyric.Language === undefined) {
            return "UNK";
        }

        if (lyric.Language.Flag === undefined || lyric.Language.Flag.Value === "") {
            return lyric.Language.Name as string;
        }

        return lyric.Language.Flag as string;
    }

    function lyricsTabs(): Array<JSX.Element> {
        const lyrics = getLyrics(props.song);

        return lyrics.map(lyric => {
            var txt = getTitle(lyric);

            return (<div className="d-flex flex-column justify-content-evenly w-100 mb-2 card glass">
                        <div className="card-header">
                            <FontAwesomeIcon icon={faMusic}/>&nbsp;{txt}
                        </div>
                        <div className="card-body">
                            <LyricsTab lyric={lyric}/>
                        </div>
                    </div>);
        });
    }

    function tiesDiv(): JSX.Element {
        if (props.ties.length === 0) {
            return (<React.Fragment/>);
        }

        return (<div className="d-flex flex-column justify-content-evenly w-100 mb-2 card glass">
                    <div className="card-header">
                        <FontAwesomeIcon icon={faSort} className="position-relative animate__animated animate__shakeX animate__repeat-3 animate__delay-1s"/>&nbsp;Tied With
                    </div>
                    <div className="card-body">
                        <TiedWithTab ties={props.ties} year={props.year} navigateTo={props.navigateTo}/>
                    </div>
                </div>);
        }

    const backDisabled = (props.runningOrder as number) === 1;
    const forwardDisabled = props.runningOrder === props.numberOfSongs;

    return (<div className="detail-pane text-white p-0 pt-2">
                <div className="d-flex flex-column justify-content-evenly w-100 mb-2">
                    <div className="card glass mb-2">
                        <div className="card-header">
                            <FontAwesomeIcon icon={faBullseye}/>&nbsp;Score
                        </div>
                        <div className="card-body">
                            <ScoreTab scores={props.scores} votingOpen={props.votingOpen} song={props.song} hashTags={props.hashTags} updateScores={props.updateScores}></ScoreTab>
                        </div>
                    </div>
                </div>
                {tiesDiv()}
                <div className="justify-content-center w-100 d-none d-lg-flex card glass mb-2">
                    <div className="card-body">
                        <div className="d-flex w-100 align-items-center justify-content-around text-large mt-auto">
                            <div className="p-2">
                                <Button variant="light" size="sm" disabled={backDisabled} onClick={props.previous}><FontAwesomeIcon icon={faBackward}/></Button>
                            </div>
                            <div className="p-2">
                                <Button variant="light" size="sm" onClick={props.eject}><FontAwesomeIcon icon={faEject}/></Button>
                            </div>
                            <div className="p-2">
                                <Button variant="light" size="sm" disabled={forwardDisabled} onClick={props.next}><FontAwesomeIcon icon={faForward}/></Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="justify-content-center w-100 d-flex d-lg-none card glass mb-2">
                    <div className="card-body">
                        <div className="d-flex w-100 align-items-center justify-content-around text-large mt-auto">
                            <div className="p-2">
                                <Button variant="light" size="lg" disabled={backDisabled} onClick={props.previous}><FontAwesomeIcon icon={faBackward}/></Button>
                            </div>
                            <div className="p-2">
                                <Button variant="light" size="lg" onClick={props.eject}><FontAwesomeIcon icon={faEject}/></Button>
                            </div>
                            <div className="p-2">
                                <Button variant="light" size="lg" disabled={forwardDisabled} onClick={props.next}><FontAwesomeIcon icon={faForward}/></Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-evenly w-100 mb-2 card glass">
                    <div className="card-header">
                        <FontAwesomeIcon icon={faFile}/>&nbsp;About
                    </div>
                    <div className="card-body">
                        <AboutTab song={props.song} drawnBy={props.drawnBy}/>
                    </div>
                </div>
                {lyricsTabs()}
            </div>);
}

export default DetailsPane;