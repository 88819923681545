import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router';
import AxiosSetup from "../api/AxiosSetup";

import {
    HistoryClient,
    Scoreboard,
    ContestYear
} from "../Client";

import CountryItem from "./Contest/CountryItem";

let axiosSetup = new AxiosSetup();

let client = new HistoryClient(undefined, axiosSetup.Create());


function HistoryContestScreen(): JSX.Element {
    const { year, round } = useParams();
    const navigate = useNavigate();

    const[
        scoreboard, setScoreboard
    ] = useState<Scoreboard[] |
                 undefined>(undefined);

    const[
        loaded, setLoaded
    ] = useState(false);

    const[
        breakPoint, setBreakPoint
    ] = useState(0);

    useEffect(() => {
            if (loaded) {
                return;
            }

            if (year === undefined || round === undefined) {
                return;
            }

            var yearAsNumber = parseInt(year);

            const promise = client.result2(yearAsNumber,
                round);

            promise.then(scoreboard => {
                if (scoreboard !== undefined) {
                    setBreakPoint(scoreboard.length % 2 === 0 ? (scoreboard.length / 2) : ((scoreboard.length + 1) / 2));
                    
                    setScoreboard(scoreboard);

                    setLoaded(true);
                }
            }).catch(async ex  => {
                if (ex.status === 401 || ex.status === 403) {
                    await axiosSetup.Refresh();
                }

                navigate("/");
            });
        },
        []);

    function toContestYear(): ContestYear | undefined {
        if (year === undefined) {
            return undefined;
        }

        const yearAsNumber = parseInt(year);

        return yearAsNumber as ContestYear;
    }

    function handleClick(item: Scoreboard) {
        navigate(`/History/${year}/${round}/${item.Rank}`);
    }

    function items(): Array<JSX.Element> {
        if (scoreboard === undefined) {
            return new Array<JSX.Element>();
        }

        return scoreboard.map(item => {
            var key = year + "|" + round + "|" + item.Rank;

            return (<CountryItem key={key} scoreboard={item} year={toContestYear()} handleClick={handleClick}/>);
        });
    }

    function firstHalf(): Array<JSX.Element> {
        if (scoreboard === undefined) {
            return new Array<JSX.Element>();
        }

        const retVal = new Array<JSX.Element>();

        for (let i = 0; i < breakPoint; i++) {
            const item = scoreboard[i];
            const key = year + "|" + round + "|" + item.Rank;

            retVal.push(<CountryItem key={key} scoreboard={item} year={toContestYear()} handleClick={handleClick}/>);
        }

        return retVal;
    }

    function secondHalf(): Array<JSX.Element> {
        if (scoreboard === undefined) {
            return new Array<JSX.Element>();
        }

        const retVal = new Array<JSX.Element>();

        for (let i = breakPoint; i < scoreboard.length; i++) {
            const item = scoreboard[i];
            const key = year + "|" + round + "|" + item.Rank;

            retVal.push(<CountryItem key={key} scoreboard={item} year={toContestYear()} handleClick={handleClick}/>);
        }

        return retVal;
    }

    return (
    <div className="m-1">
        <div className="d-flex flex-wrap justify-content-center w-100">
            <h2 className="text-light">{year} {round}</h2>
        </div>
        <div className="row d-none d-xl-flex d-lg-flex mt-2 me-0">
            <div className="col-6">
                <div className="card border-light">
                    <ul className="list-group list-group-flush text-dark">
                        {firstHalf()}
                    </ul>
                </div>
            </div>
            <div className="col-6 ">
                <div className="card border-light glass">
                    <ul className="list-group list-group-flush text-dark">
                        {secondHalf()}
                    </ul>
                </div>
            </div>
        </div>
        <div className="row d-flex d-lg-none d-xl-none mt-0 me-0">
            <div className="col-12">
                <div className="card border-light">
                    <ul className="list-group list-group-flush text-dark">
                        {items()}
                    </ul>
                </div>
            </div>
        </div>
    </div>);

}

export default HistoryContestScreen;