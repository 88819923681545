import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import {
    Song,
    Lyric,
    ContestNumberOfSongs,
    ContestYear,
    ContestRound,
    HistoryItem
} from "../../Client";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMusic, faBullseye, faFile, faBackward, faForward, faEject, faSort } from "@fortawesome/free-solid-svg-icons";
import { Button, Nav, Tab } from 'react-bootstrap';
import AboutTab from './AboutTab';
import LyricsTab from './LyricsTab';
import ScoredHistoryTab from './ScoredHistoryTab';

interface IHistoryDetailPaneProps {
    item: HistoryItem | undefined;
    numberOfSongs: ContestNumberOfSongs | undefined;
    year: ContestYear | undefined;

    next() : void;
    previous() : void;
    eject() : void;
}

interface ILyricProps {
    children: Lyric[] | null | undefined;
    keyPrefix: string;
}

function DetailsPaneNavigation(props: ILyricProps) : JSX.Element {
    function items(): Array<JSX.Element> {
        if (props.children === undefined || props.children === null) {
            return new Array<JSX.Element>();
        }

        return props.children.map(lyric => {
            var key = props.keyPrefix + "|" + lyric.Language?.Id;


            var txt = getTitle(lyric);

            var href = `LYRICTAB|${lyric.Language?.Id}`;

            return (<Nav.Item key={key}>
                        <Nav.Link eventKey={href}>
                            <span><FontAwesomeIcon icon={faMusic}/>&nbsp;{txt}</span>
                        </Nav.Link>
                    </Nav.Item>);
        });
    }

    function getTitle(lyric : Lyric) : string
    {
        if (lyric.Language === undefined) {
            return "UNK";
        }

        if (lyric.Language.Flag === undefined || lyric.Language.Flag === "") {
            return lyric.Language.Name as string;
        }

        return lyric.Language.Flag as string;
    }

    return (<Nav className="text-medium nav nav-tabs border-0 d-flex mb-1 clickable">
                <Nav.Item>
                    <Nav.Link eventKey="score">
                        <span><FontAwesomeIcon icon={faBullseye} />&nbsp;Score</span>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="about">
                        <span><FontAwesomeIcon icon={faFile} />&nbsp;About</span>
                    </Nav.Link>
                </Nav.Item>
                {items()}
            </Nav>);
}

function HistoryDetailsPane(props: IHistoryDetailPaneProps) {
    const navigate = useNavigate();

    const [
        activeKey, setActiveKey
    ] = useState("score");

    useEffect(() => {
            setActiveKey("score");
        },
        [
            props
        ]);

    function getLyrics(song: Song | undefined) : Lyric[]
    {
        if (song === undefined) {
            return new Array<Lyric>();
        }

        if (song.Lyrics === undefined || song.Lyrics === null) {
            return new Array<Lyric>();
        }

        return song.Lyrics;
    }

    function getTitle(lyric : Lyric) : string
    {
        if (lyric.Language === undefined) {
            return "UNK";
        }

        if (lyric.Language.Flag === undefined || lyric.Language.Flag.Value === "") {
            return lyric.Language.Name as string;
        }

        return lyric.Language.Flag as string;
    }

    function lyricsTabs(): Array<JSX.Element> {
        if (props.item === undefined) {
            return new Array<JSX.Element>();
        }

        const lyrics = getLyrics(props.item.Song);

        return lyrics.map(lyric => {
            var txt = getTitle(lyric);

            return (<div className="d-flex flex-column justify-content-evenly w-100 mb-2 card glass">
                        <div className="card-header">
                            <FontAwesomeIcon icon={faMusic}/>&nbsp;{txt}
                        </div>
                        <div className="card-body">
                            <LyricsTab lyric={lyric}/>
                        </div>
                    </div>);
        });
    }

    if (props.item === undefined) {
        return (<React.Fragment/>);
    }

    const rankAsNumber = props.item.Rank as number;

    const backDisabled = rankAsNumber === 1;
    const forwardDisabled = props.item.Rank === props.numberOfSongs;

    return (<div className="detail-pane text-white p-2">
                <div className="d-flex flex-column justify-content-evenly w-100 mb-2">
                    <div className="card glass mb-2">
                        <div className="card-header">
                            <FontAwesomeIcon icon={faBullseye}/>&nbsp;Score
                        </div>
                        <div className="card-body">
                            <ScoredHistoryTab item={props.item}/>
                        </div>
                    </div>
                </div>
                <div className="justify-content-center w-100 d-none d-lg-flex card glass mb-1">
                    <div className="card-body">
                        <div className="d-flex w-100 align-items-center justify-content-around text-large mt-auto">
                            <div className="p-2">
                                <Button variant="light" size="sm" disabled={backDisabled} onClick={props.previous}><FontAwesomeIcon icon={faBackward}/></Button>
                            </div>
                            <div className="p-2">
                                <Button variant="light" size="sm" onClick={props.eject}><FontAwesomeIcon icon={faEject}/></Button>
                            </div>
                            <div className="p-2">
                                <Button variant="light" size="sm" disabled={forwardDisabled} onClick={props.next}><FontAwesomeIcon icon={faForward}/></Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="justify-content-center w-100 d-flex d-lg-none card glass mb-1">
                    <div className="card-body">
                        <div className="d-flex w-100 align-items-center justify-content-around text-large mt-auto">
                            <div className="p-2">
                                <Button variant="light" size="lg" disabled={backDisabled} onClick={props.previous}><FontAwesomeIcon icon={faBackward}/></Button>
                            </div>
                            <div className="p-2">
                                <Button variant="light" size="lg" onClick={props.eject}><FontAwesomeIcon icon={faEject}/></Button>
                            </div>
                            <div className="p-2">
                                <Button variant="light" size="lg" disabled={forwardDisabled} onClick={props.next}><FontAwesomeIcon icon={faForward}/></Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column justify-content-evenly w-100 mb-2 card glass">
                    <div className="card-header">
                        <FontAwesomeIcon icon={faFile}/>&nbsp;About
                    </div>
                    <div className="card-body">
                        <AboutTab song={props.item.Song} drawnBy={props.item.DrawnBy}/>
                    </div>
                </div>
                {lyricsTabs()}
            </div>);
}

export default HistoryDetailsPane;