import React from 'react';
import {
    ScoresheetItem,
    ContestYear,
    RunningOrder
} from "../../Client";
import FlagLarge from "../Country/FlagLarge";
import FlagMedium from "../Country/FlagMedium";

interface ITiedWithTabProps {
    ties: ScoresheetItem[];
    year: ContestYear | undefined;
    navigateTo(runnningOrder: RunningOrder) : void;
}

interface ITiedWithItemProps {
    tie: ScoresheetItem;
    year: ContestYear | undefined;
    navigateTo(runnningOrder: RunningOrder) : void;
}

function TiedWithItem(props: ITiedWithItemProps) : JSX.Element {
    function handleClick(e: any): void {
        if (props.tie == undefined || props.tie.RunningOrder === undefined) {
            return;
        }

        props.navigateTo(props.tie.RunningOrder);
    }

    return (
        <div className="scoresheet-background-required clickable" onClick={handleClick}>
            <div className="row">
                <div className="col-12 align-content-center">
                    <FlagLarge country={props.tie.Country} year={props.year} css="rounded d-none d-md-block d-lg-block d-xl-block"/>
                    <FlagMedium country={props.tie.Country} year={props.year} css="rounded d-block d-md-none d-lg-none d-xl-none"/>
                </div>
            </div>
            <div className="row">
                <div className="d-none d-md-flex d-lg-flex d-xl-flex col-12">
                    <span className="text-small text-nowrap">{props.tie.Country?.Name}</span>
                </div>
            </div>
        </div>);
}


function TiedWithTab(props: ITiedWithTabProps) {
    function items(): Array<JSX.Element> {
        return props.ties.map(tie => {
            var key = `TIEITEM|${tie.RunningOrder}`;

            return (<TiedWithItem tie={tie} year={props.year} navigateTo={props.navigateTo} key={key}/>);
        });
    }

    if (props.ties.length === 0) {
        return (<React.Fragment/>);
    }

    return (<div className="d-flex w-100 align-items-center">
                <div className="p-2">
                    {items()}
                </div>
            </div>);
}

export default TiedWithTab;