import React, { useEffect, useState } from 'react';

import AxiosSetup from "../api/AxiosSetup";

import {
    ScoresheetClient,
    ContestClient,
    ScoresheetItem,
    ContestYear,
    VotingOpen,
    ResultsAvailable,
    ContestNumberOfSongs,
    RunningOrder,
    ContestCloseTime,
    Complete
} from "../Client";

import ArtistPane from "./Details/ArtistPane";
import LiveTimelinePane from "./Details/LiveTimelinePane";

let axiosSetup = new AxiosSetup();

let client = new ScoresheetClient(undefined, axiosSetup.Create());

interface IInContestPresenterScreenProps {
    runningOrder: RunningOrder | undefined;
    setPresenterMode(presenterMode: boolean) : void;
}

interface IInfoPaneProps {
    children: ScoresheetItem | undefined;
    year: ContestYear | undefined;
    votingOpen : VotingOpen | undefined;
    numberOfSongs : ContestNumberOfSongs | undefined;
}


function InfoPane(props: IInfoPaneProps) {
    if (props.children === undefined) {
        return (<React.Fragment/>);
    }

    return (<div className="card-body p-0">
                <ArtistPane country={props.children.Country} song={props.children.Song} runningOrder={props.children.RunningOrder} year={props.year} includeSongInformation={false}/>
            </div>);
}

function runningOrderToInt(props: IInContestPresenterScreenProps): number {
    if (props.runningOrder === undefined) {
        return 1;
    }

    return parseInt(props.runningOrder.toString());
}


function InContestPresenterScreen(props: IInContestPresenterScreenProps) : JSX.Element {

    const [complete, setComplete] = useState<Complete | undefined>(undefined);

    useEffect(() => {
        props.setPresenterMode(true);

        const promise = client.complete2();

        promise.then(complete => {
                if (complete !== undefined) {
                    setComplete(complete);
                }
            })
            .catch(async ex => {
                if (ex.status === 401 || ex.status === 403) {
                    await axiosSetup.Refresh();
                }
            });
    }, []);

    function FindItem(items: Array<ScoresheetItem> | null | undefined,
        runningOrder: number): ScoresheetItem | undefined {
        if (items === undefined || items === null) {
            return undefined;
        }
        
        for (let i = 0; i < items.length; i++) {
            const itemRunningOrder = items[i].RunningOrder as number;

            if (itemRunningOrder === runningOrder) {
                return items[i];
            }
        }

        return undefined;
    }

    if (complete !== undefined) {
        const item = FindItem(complete.Items,
            runningOrderToInt(props));

        return(
            <div className="d-flex m-5 flex-lg-row flex-md-row flex-column">
                <div className="flex-grow-1 me-2">
                    <div className="card glass presenter-detail text-dark m-1 w-100 border-0">
                        <InfoPane year={complete.ContestYear} votingOpen={complete.VotingOpen} numberOfSongs={complete.NumberOfSongs}>{item}</InfoPane>
                    </div>
                </div>
            </div>);
    }

    return (<React.Fragment/>);
}

export default InContestPresenterScreen;