import * as React from "react";
import IFlagProps from "./IFlagProps";
import GetIsoCode from "./GetIsoCode";

function FlagTiny(props: IFlagProps | undefined): JSX.Element {
    if (props === undefined) {
        return (<React.Fragment/>);
    }

    const isoCode = GetIsoCode(props.country);
    const year = props.year;

    const baseUrl = `${process.env.REACT_APP_API_URL}api/Country/${isoCode}/Flag/Tiny/${year}/`;

    const image = baseUrl + "1";

    const sourceSet = baseUrl + "1 1x, " + baseUrl + "1.5 1.5x, " + baseUrl + "2 2x, " + baseUrl + "3 3x, " + baseUrl + "4 4x";

    const countryName = props.country?.Name as string;

    let css = "flag-tiny";

    if (props.css !== undefined) {
        css = css + " " + props.css;
    }

    return (
        <img src={image} srcSet={sourceSet} alt={countryName} className={css}/>);
}

export default FlagTiny;