import React, { useEffect, useState } from 'react';
import moment from 'moment';

import {
    ContestCloseTime,
    ResultsAvailable
} from "../Client";

interface ICountdownProps {
    closeTime: ContestCloseTime | undefined | null;
    resultsAvailable: ResultsAvailable | undefined |null;
}

function Countdown(props: ICountdownProps) {

    const [
        text, setText
    ] = useState<string>("");

    useEffect(() => {
        if (props.resultsAvailable === undefined || props.resultsAvailable === null || props.resultsAvailable === true) {
            setText("");
            return;
        }

        if (props.closeTime === undefined || props.closeTime === null) {
            setText("");
            return;
        }

        const sampleInterval = setInterval(() => {
                const now = moment(Date.now());
                const then = moment(props.closeTime as number);

                if (now > then) {
                    setText("");
                    clearInterval(sampleInterval);
                    return;
                }

                setText(moment.utc(then.diff(now))
                    .format("mm:ss"));
            },
            1000);

        return () => {
            clearInterval(sampleInterval);
        };
    },
    [
        props
    ]);

    if (props.closeTime === undefined || props.closeTime === null || text === "") {
        return(<React.Fragment/>);
    }

    return (<div className="row p-0 m-1">
                <div className="col-12 text-center p-0">
                    <span className="h1 font-monospace text-white">{text}</span>
                </div>
            </div>);
}

export default Countdown;
