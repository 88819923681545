import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from "react-router-dom";
import AxiosSetup from "../api/AxiosSetup";
import {
    ScoresheetClient,
    ScoresheetItem,
    Complete,
    Song,
    ContestYear,
    ScoredCriteria,
    VotingOpen,
    ContestNumberOfSongs,
    HashTag,
    RunningOrder
} from "../Client";

import ArtistPane from "./Details/ArtistPane";
import DetailsPane from "./Details/DetailsPane";

let axiosSetup = new AxiosSetup();

let client = new ScoresheetClient(undefined, axiosSetup.Create());

interface IScoresheetItemProps {
    children: ScoresheetItem | undefined;
    year: ContestYear | undefined;
    votingOpen : VotingOpen | undefined;
    numberOfSongs : ContestNumberOfSongs | undefined;
    hashTags: HashTag[] | null | undefined;
    ties: ScoresheetItem[];

    next(event : any) : void;
    previous(event : any) : void;
    eject(event : any) : void;
    navigateTo(runningOrder: RunningOrder) : void;

    updateScores(song: Song | undefined, scoredCriteria: ScoredCriteria[]) : void;
}


function ScoresheetPane(props: IScoresheetItemProps) {
    if (props.children === undefined) {
        return (<React.Fragment/>);
    }

    return (<div className="p-0">
                    <div className="d-flex flex-column">
                        <ArtistPane country={props.children.Country} song={props.children.Song} runningOrder={props.children.RunningOrder} year={props.year} includeSongInformation={true}/>
                        <DetailsPane song={props.children.Song} scores={props.children.Scores} votingOpen={props.votingOpen} runningOrder={props.children.RunningOrder} numberOfSongs={props.numberOfSongs} next={props.next} previous={props.previous} eject={props.eject} hashTags={props.hashTags} drawnBy={props.children.DrawnBy} updateScores={props.updateScores} ties={props.ties} year={props.year} navigateTo={props.navigateTo}/>
                    </div>
                </div>);
}

function ScoresheetView() {
    const navigate = useNavigate();

    const { runningOrder } = useParams();

    const [
        currentView, setCurrentView
    ] = useState(parseInt(runningOrder ?? "1"));

    const [
        fadeIn, setFadeIn
    ] = useState(true);

    const[
        ties, setTies
    ] = useState(new Array<ScoresheetItem>());

    const [complete, setComplete] = useState<Complete | undefined>(undefined);

    useEffect(() => {
        const promise = client.complete2();

        promise.then(complete => {
                if (complete !== undefined) {
                    setComplete(complete);
                }
            })
            .catch(async ex => {
                if (ex.status === 401 || ex.status === 403) {
                    await axiosSetup.Refresh();
                }
            });
    }, []);

    useEffect(() => {
            window.history.replaceState(null,
                "Beavervision",
                `/Scoresheet/Contest/${currentView}`);
        },
        [
            currentView
        ]);

    useEffect(() => {
            updateTies();
        },
        [
            complete
        ]);

    useEffect(() => {
            updateTies();
        },
        [
            currentView
        ]);

    function FindItem(items: Array<ScoresheetItem> | null | undefined,
        runningOrder: number): ScoresheetItem | undefined {
        if (items === undefined || items === null) {
            return undefined;
        }
        
        for (let i = 0; i < items.length; i++) {
            const itemRunningOrder = items[i].RunningOrder as number;

            if (itemRunningOrder === runningOrder) {
                return items[i];
            }
        }

        return undefined;
    }

    function next(event: any) {
        window.scrollTo(0, 0);
        setCurrentView(currentView + 1);
        setFadeIn(true);
    }

    function previous(event: any) {
        window.scrollTo(0, 0);
        setCurrentView(currentView - 1);
        setFadeIn(true);
    }

    function handleAnimationEnd(event : any) {
        setFadeIn(false);
    }

    function eject(event: any) {
        navigate("/");
    }

    function navigateTo(runningOrder: RunningOrder) {
        setCurrentView(runningOrder as number);
        setFadeIn(true);
    }

    function updateScores(song: Song |
                                undefined,
        scoredCriteria: ScoredCriteria[]): void {
        if (song === undefined) {
            return;
        }

        if (complete === undefined) {
            return;
        }

        const workingComplete = complete;

        if (workingComplete.Items === undefined || workingComplete.Items === null) {
            return;
        }

        for (let i = 0; i < workingComplete.Items.length; i++) {
            const scoresheetItem = workingComplete.Items[i];

            if (scoresheetItem.Song === undefined) {
                return;
            }

            if (scoresheetItem.Song.Id === song.Id) {
                scoresheetItem.Scores = scoredCriteria;
                setComplete(workingComplete);
                updateTies();
                return;
            }
        }
    }

    function updateTies() : void {
        if (complete === undefined || complete === null) {
            setTies(new Array<ScoresheetItem>());
            return;
        }

        if (complete.Items === undefined || complete.Items === null) {
            setTies(new Array<ScoresheetItem>());
            return;
        }

        const currentItem = FindItem(complete.Items,
            currentView);

        if (currentItem === undefined) {
            setTies(new Array<ScoresheetItem>());
            return;
        }

        const currentScore = getTotal(currentItem);

        if (currentScore === 0) {
            setTies(new Array<ScoresheetItem>());
            return;
        }
        
        const workList = new Array<ScoresheetItem>();

        for (let i = 0; i < complete.Items.length; i++) {
            const work = complete.Items[i];

            if (work.RunningOrder === undefined || work.RunningOrder === null) {
                continue;
            }

            if (work.RunningOrder as number === currentView) {
                continue;
            }

            const otherScore = getTotal(work);

            if (otherScore === currentScore) {
                workList.push(work);
            }
        }

        setTies(workList);
    }

    function getTotal(item: ScoresheetItem): number {
        let retVal = 0;

        if (item.Scores !== null && item.Scores !== undefined) {
            for (let i = 0; i < item.Scores.length; i++) {
                const score = item.Scores[i];

                retVal += ((score.Value as number) * (score.Multiplier as number));
            }
        }

        return retVal;
    }

    if (complete !== undefined) {
        const item = FindItem(complete.Items,
            currentView);

        const css = fadeIn
            ? "contest-detail text-dark border-0 animate__animated animate__fast animate__fadeIn"
            : "contest-detail text-dark border-0 animate__animated animate__fast";

        return (
                <div className="d-flex m-2 flex-lg-row flex-md-row flex-column">
                    <div className="flex-grow-1">
                        <div className={css} onAnimationEnd={handleAnimationEnd}>
                            <ScoresheetPane year={complete.ContestYear} votingOpen={complete.VotingOpen} numberOfSongs={complete.NumberOfSongs} next={next} previous={previous} eject={eject} hashTags={complete.HashTags} updateScores={updateScores} ties={ties} navigateTo={navigateTo}>{item}</ScoresheetPane>
                        </div>
                    </div>
                </div>);
    }

    return (<React.Fragment/>);
}

export default ScoresheetView